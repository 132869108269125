import { useState } from "react";
import { ContactItemType } from "../../../../types";
import { filterContactItemsByText } from "../../../../utils/others";
import AllContacts from "../../../UI/Contacts/AllContacts";
import SidebarHeader from "../../../UI/Contacts/SidebarHeader";
import { StyledSidebar, StyledContactList } from "./style";

type Props = {
  items: ContactItemType[];
  activeId?: number;
  clickHandler: (item: ContactItemType) => void;
  disableSections?: boolean;
  onAddClick?: () => void;
};

const Wrapper: React.FC<Props> = ({
  items,
  activeId,
  clickHandler,
  disableSections,
  onAddClick,
}: Props) => {
  const [searchedText, setSearchedText] = useState("");

  return (
    <StyledSidebar $show={true}>
      <SidebarHeader
        searchedText={searchedText}
        setSearchedText={setSearchedText}
        onAddClick={onAddClick}
      />
      <StyledContactList>
        <AllContacts
          items={filterContactItemsByText(searchedText, items)}
          activeId={activeId}
          clickHandler={clickHandler}
          disableSections={disableSections}
        />
      </StyledContactList>
    </StyledSidebar>
  );
};

export default Wrapper;
