const it = {
  common: {
    title: "Imc Holding",
    practice: "Pratica",
    practices: "Pratiche",
    practicesInPayment: "Pratiche in pagamento questo mese",
    users: "Utenti",
    role: "Ruolo",
    email: "Email",
    password: "Password",
    confirmPassword: "Conferma password",
    backToLogin: "Torna al login",
    confirm: "Conferma",
    cancel: "Cancella",
    addPractice: "Carica Pratica",
    name: "Nome",
    surname: "Cognome",
    phone: "Cellulare",
    credentials: "Credenziali",
    profile: "Profilo",
    logout: "Logout",
    delete: "Elimina",
    edit: "Modifica",
    nobody: "Nessuno",
  },
  months: {
    1: "Gennaio",
    2: "Febbraio",
    3: "Marzo",
    4: "Aprile",
    5: "Maggio",
    6: "Giugno",
    7: "Luglio",
    8: "Agosto",
    9: "Settembre",
    10: "Ottobre",
    11: "Novembre",
    12: "Dicembre",
  },
  roles: {
    admin: "Amministratore",
    junior: "Energy Marker Junior",
    senior: "Energy Marker Senior",
    supervisor: "Supervisor",
    "area-manager": "Area manager",
    no_role: "Nessun ruolo",
    formedJuniors: "Junior formati",
    formedSeniors: "Senior formati",
    acceditedBonuses: "Bonus accreditati",
  },
  components: {
    signinForm: {
      title: "Login",
      description: "Bentornato! Accedi per continuare.",
      forgotPassword: "Password dimenticata?",
      signin: "Accedi",
      email: "Email",
    },
    credentialsForm: {
      email: "Email",
      password: "Nuova password",
      confirmPassword: "Conferma nuova password",
    },
    forgotPasswordForm: {
      title: "Recupera la tua password",
      description:
        "Inserisci la tua email e invieremo un link per modificare la tua password.",
      email: "Email",
      resetPassword: "Recupera password",
      confirmMessage:
        "Richiesta inviata correttamente. Controlla la tua mail e segui le istruzioni indicate.",
    },
    changePasswordForm: {
      title: "Cambia la tua password",
      description:
        "Inserisci la tua nuova password per completare l'operazione.",
      confirmPassword: "Conferma password",
    },
    competitionForm: {
      title: "Competizione",
      startDate: "Data di inizio",
      endDate: "Data di fine",
      deleteCompetitionText: "Sei sicuro di voler eliminare questa gara?",
    },
    objectiveForm: {
      title: "Checklist",
      text: "Descrizione",
    },
    salesThresholdForm: {
      title: "Soglia vendite",
      quantity: "Soglia",
      bonus: "Bonus",
    },
    managementCompetitionForm: {
      title: "Gare Manageriali",
      sectionTitle: "Gare Manageriali",
      monthsAfterCreation: "Mese di lavoro",
      juniorClosedContracts: "Contratti chiusi dallo junior",
      bonus: "Bonus",
    },
    calendar: {
      today: "Questo mese",
    },
    menu: {
      menu: "Menu",
      practices: "Pratiche",
      usersManagement: "Gestione utenti",
      rolesManagement: "Gestione ruoli",
      payments: "Pagamenti",
    },
    practicesTable: {
      id: "Id",
      user: "Commerciale",
      customer: "Cliente",
      date: "Data",
      totalAmount: "Importo",
      state: "Stato",
      validated: "Validata",
      toValidate: "Da validare",
      rejected: "Rifiutata",
      paymentIn: "Pagamento a ",
    },
    usersWithCommissionsTable: {
      id: "Id",
      name: "Nome",
      role: "Ruolo",
      sells: "Numero vendite",
      commissions: "Commissioni",
      bonuses: "Bonus",
      totalCommissions: "Commissioni totali",
      reversal: "Importo stornato",
      toPay: "Da pagare",
    },
    practiceModal: {
      createPractice: "Nuova pratica",
    },
    practiceForm: {
      user: "Commerciale",
      customer: "Cliente",
      date: "Data",
      totalAmount: "Importo",
      description: "Note aggiuntive",
      validate: "Valida",
      validateThisMonth: "Valida - Pagamento questo mese",
      validateNextMonth: "Valida - Pagamento il prossimo mese",
      reject: "Rifiuta",
    },
    userModal: {
      createUser: "Crea utente",
      deleteUser: "Elimina utente",
      deleteUserText: "Sei sicuro di volere eliminare l'utente?",
      updateUser: "Modifica utente",
    },
    contacts: {
      search: "Cerca...",
      userData: "Informazioni utente",
      practices: "Pratiche",
      configuration: "Configurazione",
    },
    careerPlan: {
      careerPlan: "Piano carriera",
      reached: "Raggiunto",
      upgradeRole: "Avanza di ruolo",
      downgradeRole: "Retrocedi di ruolo",
    },
    roleSummary: {
      checklist: "Checklist piano carriera",
      currentCompetition: "Gara corrente",
      noCompetition:
        "Per il periodo selezionato non è presente alcuna gara.\nVuoi crearne una?",
      createCompetition: "Crea gara",
    },
    progressCard: {
      inPayment: "In pagamento questo mese",
      inPaymentTooltip: "Importo totale in pagamento.",
      reversal: "Importo stornato",
      reversalTooltip: "Importo da scalare a quello totale in pagamento.",
      sells: "Vendite kit fotovoltaico da 3kw",
      sellsBonus: "Bonus vendite kit fotovoltaico da 3kw",
      commissions: "Provvigione sulle vendite",
      commissionsPercentage: "Percentuale provvigione",
      activeKits: "Kit attivi",
      monthlyBonus: "Bonus mensile",
    },
    thresholdTable: {
      sectionTitle: "Bonus vendite kit fotovoltaico da 3kw",
      salesThreshold: "Soglia vendite",
      bonus: "Bonus",
      action: "Azioni",
    },
    extraThresholdTable: {
      sectionTitle: "Extra bonus vendite kit fotovoltaico da 3kw",
      salesThreshold: "Soglia vendite",
      bonus: "Bonus",
      action: "Azioni",
    },
    managementCompetitionTable: {
      sectionTitle: "Gare Manageriali",
      monthsAfterCreation: "Mese di lavoro",
      juniorClosedContracts: "Contratti chiusi dallo junior",
      bonus: "Bonus",
      action: "Azioni",
    },
  },
  screens: {
    payments: {
      usersNumber: "Utenti totali",
      totalPayments: "Commissioni totali da pagare questo mese",
    },
  },
  messages: {
    formValidations: {
      common: {
        emailRequired: "Inserire l'indirizzo email",
        passwordRequired: "Inserire password",
        passwordMinLength: "Lunghezza minima di 6 caratteri",
        passwordsNotMatch: "Le password non coincidono",
      },
    },
    errors: {
      signinForm: {
        wrongCredentials: "Credenziali non valide",
      },
      forgotPasswordForm: {
        wrongEmail: "Email errata",
      },
      credentialsForm: {
        emailRequired: "Inserire l'indirizzo email",
        nameRequired: "Inserire il nome",
        surnameRequired: "Inserire il cognome",
        phoneNumberRequired: "Inserire il numero di telefono",
        phoneNumberNotValid: "Inserire un numero di telefono valido",
      },
      changePasswordForm: {
        generic: "C'è stato un problema con l'aggiornamento della password",
      },
      updateUserForm: {
        generic: "C'è stato un problema con l'aggiornamento dei dati",
      },
      createUserForm: {
        generic: "C'è stato un problema con la creazione dell'utente",
      },
      practiceForm: {
        customerRequired: "Inserire il nome del cliente",
        dateRequired: "Inserire la data della pratica",
        totalAmountRequired: "Inserire l'importo della pratica",
      },
      competitionForm: {
        startDateRequired: "Inserire la data di inizio",
        endDateRequired: "Inserire la data di fine",
        invalidPeriod:
          "Il periodo di tempo inserito non è valido. Controllare che la data di inizio sia precedente alla data di fine e non ci siano sovrapposizioni tra le gare.",
      },
      objectiveForm: {
        textRequired: "Inserire la descrizione",
      },
      salesThresholdForm: {
        quantityRequired: "Inserire la soglia",
        bonusRequired: "Inserire il bonus",
      },
      managementCompetitionForm: {
        juniorClosedContractsRequired: "Inserire il numero di contratti",
        monthsAfterCreationRequired: "Inserire il mese di lavoro",
        bonusRequired: "Inserire il bonus",
      },
    },
    successes: {
      forgotPasswordForm: {
        sentEmail:
          "Abbiamo inviato una mail all'indirizzo indicato contenente un link per modificare la password",
      },
      changePasswordForm: {
        updatedPassword: "La password è stata aggiornata con successo!",
      },
      updateUserForm: {
        generic: "I dati sono stati aggiornati con successo!",
      },
      userForm: {
        generic: "L'utente è stato creato con successo!",
      },
    },
  },
};

export default it;
