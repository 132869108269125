import { useTranslation } from "react-i18next";
import { HiOutlineKey } from "react-icons/hi";
import { Flex } from "rebass";
import { User } from "../../../types";
import { UserCredentialsForm } from "../UserCredentialsForm";
import { CredentialsContainer, CredentialsText } from "./style";

type Props = {
  user: User;
  onUpdateUser: (
    email: string,
    password?: string,
    confirmPassword?: string
  ) => void;
};

export const UserCredentials: React.FC<Props> = ({
  user,
  onUpdateUser,
}: Props) => {
  const { t } = useTranslation();
  return (
    <CredentialsContainer>
      <Flex alignItems={"center"} mb={"2rem"}>
        <HiOutlineKey size={"1.3rem"} />
        <CredentialsText>{t("common.credentials")}</CredentialsText>
      </Flex>
      <UserCredentialsForm onSubmit={onUpdateUser} initialValues={user} />
    </CredentialsContainer>
  );
};
