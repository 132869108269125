import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border: 1px solid #e5e5e5;
  border-collapse: separate;
  border-left: 0;
  border-radius: 6px;
  border-spacing: 0px;
`;

export const Thead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
  background-color: #f5f6fa;

  &:first-child {
    border-radius: 6px 0 0 0;
  }
  &:last-child {
    border-radius: 0 0 0 6px;
  }
`;

export const Tbody = styled.tbody`
  background-color: white;
  &:first-child {
    border-radius: 6px 0 0 0;
  }
  &:last-child {
    border-radius: 6px 0 0 0;
  }
`;

export const Tr = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;

  &:first-child {
    border-radius: 6px 0 0 0;
  }
  &:last-child {
    border-radius: 0 0 0 6px;
  }
`;

export const Td = styled.td`
  padding: 0.5rem;
  text-align: left;
  vertical-align: center;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  padding-left: 1rem;

  &:last-child {
    border-radius: 0 0 0 0px;
  }

  &:first-child {
    border-radius: 0 0 0 0px;
  }
`;

export const Th = styled.th`
  padding: 5px 4px 6px 4px;
  text-align: left;
  vertical-align: top;
  border-left: 1px solid #e5e5e5;
  padding-left: 1rem;

  &:first-child {
    border-radius: 6px 0 0 0;
  }
`;
