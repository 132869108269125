import React, { useMemo } from "react";
import styled from "styled-components";
import { Button as RebassButton, Flex } from "rebass/styled-components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import dayjs from "dayjs";
import { useCallback } from "react";
import { Period } from "../../../types";

import { Icon } from "../Icon";

type CalendarNavButtonsProps = {
  initialDate?: Date;
  type?: "month";
  disabled?: boolean;
  onChange?: (period: Period) => void;
};

export const CalendarNavButtons: React.FC<CalendarNavButtonsProps> = ({
  initialDate = new Date(),
  type = "month",
  disabled = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dayjs(initialDate));

  const isDisabledTodayButton = useMemo(() => {
    return (
      (type === "month" &&
        dayjs().startOf("month").isSame(date.startOf("month"))) ||
      disabled
    );
  }, [date, disabled, type]);

  const emitOnChange = useCallback(
    (newDate: dayjs.Dayjs) => {
      let period: Period;
      switch (type) {
        case "month":
          period = {
            fromDate: newDate.startOf("month").toDate(),
            toDate: newDate.endOf("month").toDate(),
          };
          break;
        default:
          period = {
            fromDate: newDate.toDate(),
            toDate: newDate.toDate(),
          };
      }
      onChange && onChange(period);
    },
    [onChange, type]
  );

  const onPrevClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "month":
        newDate = date.subtract(1, "month");
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  const onTodayClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "month":
        newDate = dayjs();
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  const onNextClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "month":
        newDate = date.add(1, "month");
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  return (
    <Flex>
      <PrevButton onClick={onPrevClick} disabled={disabled}>
        <Icon iconName={"HiArrowLeft"} />
      </PrevButton>
      <TodayButton disabled={isDisabledTodayButton} onClick={onTodayClick}>
        {t("components.calendar.today")}
      </TodayButton>
      <NextButton onClick={onNextClick} disabled={disabled}>
        <Icon iconName={"HiArrowRight"} />
      </NextButton>
    </Flex>
  );
};

const TodayButton = styled(RebassButton)`
  padding: 0.75rem 2rem;
  background-color: white;
  border-radius: 1rem;
  text-align: center;
  font-size: 16px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: black;
  box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
  z-index: 1;
`;

const PrevButton = styled(RebassButton)`
  padding: 0.75rem 1rem;
  background-color: #563d7c80;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  margin-right: -0.5rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: white;
`;

const NextButton = styled(RebassButton)`
  background-color: #563d7c80;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: -0.5rem;
  text-align: center;
  font-size: 1rem;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: white;
`;
