import { useTranslation } from "react-i18next";
import { RadialPercentage } from "../../UI/RadialPercentage";
import {
  CareerPlanText,
  CheckContainer,
  CheckText,
  Container,
  ExternalCard,
  RoleText,
} from "./style";
import { ReactComponent as HiBadgeCheck } from "./../../../assets/HiBadgeCheck.svg";
import { ReactComponent as HiBadgeEmpty } from "./../../../assets/HiBadgeEmpty.svg";
import { Objective, Role } from "../../../types";
import { TextInput } from "../TextInput";
import { getObjectivesPercentage } from "../../../utils/others";
import { formatPercentage } from "../../../utils/methods";
import { Button } from "../Button";
import { useCallback } from "react";

type Props = {
  variant: "essential" | "complete";
  role: Role;
  objectives?: Objective[];
  onEditCheck?: (id: number, completed: boolean) => any;
  onUpdateUserRole?: (updateType: "upgrade" | "downgrade") => any;
  canUpgradeCurrentUserRole?: boolean;
  canDowngradeCurrentUserRole?: boolean;
};

export const CareerPlan: React.FC<Props> = ({
  variant,
  role,
  objectives,
  onEditCheck,
  onUpdateUserRole,
  canUpgradeCurrentUserRole,
  canDowngradeCurrentUserRole,
}: Props) => {
  const { t } = useTranslation();

  const areAllObjectivesChecked = useCallback(
    (objectivesToCheck: Objective[]): boolean => {
      let toReturn = true;

      objectivesToCheck.map((objective) => {
        if (!objective.completed) toReturn = false;
        return toReturn;
      });
      return toReturn;
    },
    []
  );

  return (
    <Container>
      <div style={{ paddingRight: "2rem" }}>
        <CareerPlanText>{t("components.careerPlan.careerPlan")}</CareerPlanText>
        <ExternalCard>
          <RadialPercentage
            percentage={
              objectives
                ? formatPercentage(getObjectivesPercentage(objectives))
                : 0
            }
            width={"100%"}
          />

          <RoleText>{t("roles." + role.name)}</RoleText>
          {variant === "complete" && (
            <div>
              {objectives &&
                objectives.map((objective) => (
                  <CheckElement objective={objective} key={objective.id} />
                ))}
            </div>
          )}
        </ExternalCard>
      </div>
      {variant === "essential" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {objectives &&
            objectives.map((objective) => (
              <CheckElement
                objective={objective}
                key={objective.id}
                onEditCheck={onEditCheck}
              />
            ))}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            {canUpgradeCurrentUserRole &&
              (!objectives || areAllObjectivesChecked(objectives)) && (
                <Button
                  mb={"0.5rem"}
                  mt={"0.5rem"}
                  onClick={() =>
                    onUpdateUserRole && onUpdateUserRole("upgrade")
                  }
                >
                  {t("components.careerPlan.upgradeRole")}
                </Button>
              )}
            {canDowngradeCurrentUserRole && (
              <Button
                mb={"0.5rem"}
                mt={"0.5rem"}
                onClick={() =>
                  onUpdateUserRole && onUpdateUserRole("downgrade")
                }
              >
                {t("components.careerPlan.downgradeRole")}
              </Button>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

type CheckElementProps = {
  editable?: boolean;
  onEditCheck?: (id: number, value: boolean) => any;
  onUpdateObjective?: (id: number, text: string) => any;
  onDeleteObjective?: (id: number) => any;
  objective: Objective;
};
export const CheckElement: React.FC<CheckElementProps> = ({
  objective,
  editable,
  onEditCheck,
  onUpdateObjective,
  onDeleteObjective,
}) => {
  const iconSize = "1.7rem";

  return (
    <CheckContainer>
      <div style={{ width: iconSize }}>
        {objective.completed ? (
          <HiBadgeCheck
            style={{
              height: iconSize,
              width: iconSize,
              cursor: onEditCheck ? "pointer" : "default",
            }}
            onClick={() => onEditCheck && onEditCheck(objective.id, false)}
          />
        ) : (
          <HiBadgeEmpty
            style={{
              height: iconSize,
              width: iconSize,
              cursor: onEditCheck ? "pointer" : "default",
            }}
            onClick={() => onEditCheck && onEditCheck(objective.id, true)}
          />
        )}
      </div>
      {!editable ? (
        <CheckText style={{ paddingLeft: "0.5rem" }}>
          {objective.text}
        </CheckText>
      ) : (
        <TextInput
          value={objective.text}
          onChange={(value) =>
            onUpdateObjective &&
            onUpdateObjective(objective.id, value.toString())
          }
          onDelete={() => {
            onDeleteObjective && onDeleteObjective(objective.id);
          }}
        />
      )}
    </CheckContainer>
  );
};
