import { makeAutoObservable } from "mobx";
import api from "../services/api";
import {
  Commission,
  Competition,
  ExtraSalesThreshold,
  ManagementCompetition,
  Objective,
  Reversal,
  Role,
  SalesThreshold,
  Stores,
  UpdateOrCreateUserParams,
  User,
} from "./../types";
import { configure } from "mobx";

configure({
  enforceActions: "never",
});

export class SessionStore {
  isInitialized = false;
  isSubmitting = false;
  stores: Stores;
  sessionToken: string | undefined = undefined;

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  user: User | null = null;

  get isLogged() {
    return this.user !== null;
  }

  signupUser = async (nominative: string, email: string, password: string) => {
    this.isSubmitting = true;
    try {
      await api.signupUser({ nominative, email, password });
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  login = async (email: string, password: string) => {
    this.isSubmitting = true;
    try {
      await api.login(email, password);
      this.sessionToken = api.getSessionToken();
      this.persistSession();
      this.user = await api.getCurrentUser();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  forgotPassword = async (email: string) => {
    return await api.forgotPassword(email);
  };

  verifyPasswordResetCode = async (
    password: string,
    passwordConfirm: string,
    passwordResetCode: string
  ) => {
    if (password === passwordConfirm)
      return await api.verifyPasswordResetCodeAndUpdatePassword(
        passwordResetCode,
        password
      );
  };

  initialize = async () => {
    try {
      this.restoreSession();
      if (this.sessionToken) {
        this.user = await api.getCurrentUser();
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isInitialized = true;
    }
  };

  restoreSession = async () => {
    try {
      const data = localStorage.getItem("session");
      const sessionData = data ? JSON.parse(data) : null;

      if (sessionData) {
        this.sessionToken = sessionData.token;
      }
    } catch (e) {
      console.log(e);
    }
  };

  persistSession = async () => {
    const data = {
      token: this.sessionToken,
    };

    localStorage.setItem("session", JSON.stringify(data));
  };

  resetSession = async () => {
    this.user = null;
    this.sessionToken = undefined;
    localStorage.removeItem("session");
    await api.logout();
  };

  //------------------------------------------ USERS ------------------------------------------
  getUsers = async (orderByName?: boolean) => {
    let toReturn: User[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.getUsers();
      if (orderByName) {
        toReturn.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  getUsersWithCommissions = async (
    year: number,
    month: number
  ): Promise<User[]> => {
    let toReturn: User[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.getUsersWithCommissions(year, month);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  getUserCommissions = async (
    userId: number,
    year: number,
    month: number
  ): Promise<Commission | null> => {
    let toReturn: Commission | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.getUserCommissions(userId, year, month);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateUser = async (user: UpdateOrCreateUserParams): Promise<User | null> => {
    let toReturn: User | null = null;
    this.isSubmitting = true;
    if (!this.user) return null;
    try {
      toReturn = await api.updateUser(+user.id, undefined, undefined, user);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateCurrentUser = async (
    email?: string,
    password?: string
  ): Promise<User | null> => {
    let toReturn: User | null = null;
    this.isSubmitting = true;
    if (!this.user) return null;
    try {
      toReturn = await api.updateUser(+this.user.id, email, password);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  createUser = async (user: UpdateOrCreateUserParams): Promise<User | null> => {
    this.isSubmitting = true;
    let toReturn: User | null = null;
    try {
      toReturn = await api.createUser(user);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  deleteUser = async (id: string): Promise<boolean> => {
    this.isSubmitting = true;
    try {
      return await api.deleteUser(id);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      this.isSubmitting = false;
    }
  };
  //------------------------------------------ ROLES ------------------------------------------

  getRoles = async (populate: ("objectives" | "users" | "role")[] = []) => {
    let toReturn: Role[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.getImcRoles(populate);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  canUpdateRole = async (
    userId: number,
    updateType: "upgrade" | "downgrade"
  ): Promise<boolean> => {
    let toReturn = false;
    this.isSubmitting = true;
    try {
      toReturn = await api.canUpdateRole(userId, updateType);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateUserRole = async (
    userId: number,
    updateType: "upgrade" | "downgrade"
  ): Promise<User | null> => {
    let toReturn: User | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.updateUserRole(userId, updateType);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  createSalesThreshold = async (
    salesThreshold: SalesThreshold,
    competitionId: number
  ): Promise<SalesThreshold | null> => {
    let toReturn: SalesThreshold | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.createSalesThreshold(salesThreshold, competitionId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateSalesThreshold = async (
    salesThreshold: SalesThreshold,
    competitionId: number
  ): Promise<SalesThreshold | null> => {
    let toReturn: SalesThreshold | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.updateSalesThreshold(salesThreshold, competitionId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  deleteSalesThreshold = async (
    salesThresholdId: number
  ): Promise<SalesThreshold | null> => {
    let toReturn: SalesThreshold | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.deleteSalesThreshold(salesThresholdId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  createExtraSalesThreshold = async (
    extraSalesThreshold: ExtraSalesThreshold,
    competitionId: number
  ): Promise<ExtraSalesThreshold | null> => {
    let toReturn: ExtraSalesThreshold | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.createExtraSalesThreshold(
        extraSalesThreshold,
        competitionId
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateExtraSalesThreshold = async (
    extraSalesThreshold: ExtraSalesThreshold,
    competitionId: number
  ): Promise<ExtraSalesThreshold | null> => {
    let toReturn: ExtraSalesThreshold | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.updateExtraSalesThreshold(
        extraSalesThreshold,
        competitionId
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  deleteExtraSalesThreshold = async (
    extraSalesThresholdId: number
  ): Promise<ExtraSalesThreshold | null> => {
    let toReturn: ExtraSalesThreshold | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.deleteExtraSalesThreshold(extraSalesThresholdId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  //---------------------------------- MANAGEMENT COMPETITIONS ----------------------------------
  createManagementCompetition = async (
    managementCompetition: ManagementCompetition,
    competitionId: number
  ): Promise<ManagementCompetition | null> => {
    let toReturn: ManagementCompetition | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.createManagementCompetition(
        managementCompetition,
        competitionId
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateManagementCompetition = async (
    managementCompetition: ManagementCompetition,
    competitionId: number
  ): Promise<ManagementCompetition | null> => {
    let toReturn: ManagementCompetition | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.updateManagementCompetition(
        managementCompetition,
        competitionId
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  deleteManagementCompetition = async (
    managementCompetitionId: number
  ): Promise<ManagementCompetition | null> => {
    let toReturn: ManagementCompetition | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.deleteManagementCompetition(managementCompetitionId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };
  //---------------------------------- COMPETITIONS ----------------------------------
  createCompetition = async (
    startDate: Date,
    endDate: Date,
    roleId: number
  ): Promise<Competition | null> => {
    let toReturn: Competition | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.createCompetition(startDate, endDate, roleId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateCompetition = async (
    newCompetition: Competition,
    roleId: number
  ): Promise<Competition | null> => {
    let toReturn: Competition | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.updateCompetition(newCompetition, roleId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  deleteCompetition = async (
    newCompetitionId: number
  ): Promise<Competition | null> => {
    let toReturn: Competition | null = null;
    this.isSubmitting = true;
    try {
      toReturn = await api.deleteCompetition(newCompetitionId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  //------------------------------------------ OBJECTIVES ------------------------------------------
  getUserObjectives = async (userId: number): Promise<Objective[]> => {
    let toReturn: Objective[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.getUserObjectives(userId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  createObjective = async (
    roleid: number,
    text: string
  ): Promise<Objective | null> => {
    let toReturn: Objective | null = null;
    try {
      toReturn = await api.createObjective(roleid, text);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      return toReturn;
    }
  };

  updateObjective = async (
    id: number,
    text: string
  ): Promise<Objective | null> => {
    let toReturn: Objective | null = null;
    try {
      toReturn = await api.updateObjective(id, text);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      return toReturn;
    }
  };

  countUsers = async (): Promise<number> => {
    try {
      return await api.countUsers();
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getTotalPayments = async (month: number, year: number): Promise<number> => {
    try {
      return await api.getTotalPayments(month, year);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  editObjectiveCheck = async (
    completed: boolean,
    objectiveId: number,
    userId?: number
  ): Promise<Objective | null> => {
    let toReturn: Objective | null = null;
    try {
      if (userId)
        toReturn = await api.editObjectiveCheck(objectiveId, userId, completed);
      else if (this.user)
        toReturn = await api.editObjectiveCheck(
          objectiveId,
          +this.user.id,
          completed
        );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      return toReturn;
    }
  };

  deleteObjective = async (id: number): Promise<Objective | null> => {
    let toReturn: Objective | null = null;
    try {
      toReturn = await api.deleteObjective(id);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      return toReturn;
    }
  };

  createOrUpdateReversal = async (
    amount: number,
    date: Date,
    userId: User["id"],
    id?: number
  ): Promise<Reversal | null> => {
    let toReturn: Reversal | null = null;
    try {
      date.setDate(5);
      toReturn = await api.createOrUpdateReversal(amount, date, userId, id);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      return toReturn;
    }
  };
}
