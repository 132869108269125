import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Flex } from "rebass";
import Wrapper from "../../components/AdminManagement/contacts/Wrapper";
import Sidebar from "../../components/AdminManagement/contacts/Sidebar";
import Main from "../../components/AdminManagement/contacts/Main";
import {
  AlertMessage,
  Competition,
  ContactItemType,
  ExtraSalesThreshold,
  ManagementCompetition,
  Role,
  SalesThreshold,
} from "../../types";
import { useStores } from "../../hooks/useStores";
import { roleToContactItemType } from "../../utils/conversions";
import { useTranslation } from "react-i18next";

export const AdminRolesManagement: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [currentRole, setCurrentRole] = useState<Role>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [message, setMessage] = useState<AlertMessage>();

  const activeRoleId = useMemo(() => {
    return params.itemId ? Number(params.itemId) : undefined;
  }, [params]);

  const handleRoleClick = useCallback(
    (item: ContactItemType) => {
      navigate(`/admin/roles-management/${item.id}`);
    },
    [navigate]
  );

  useEffect(() => {
    const changeRole = async () => {
      setCurrentRole(roles.find((role) => +role.id === activeRoleId)); //activeId represents the index of the role user in the list
    };
    changeRole();
  }, [activeRoleId, roles]);

  useEffect(() => {
    const init = async () => {
      if (session.user) {
        try {
          setRoles(await session.getRoles(["objectives", "role"]));
        } catch (e) {
          console.log(e);
        }
      }
    };
    init();
  }, [session, session.user]);

  useEffect(() => {
    const init = async () => {
      try {
        //getUsers;
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (location.state && location.state.message) {
      //setMessage(location.state.message);
    }
  }, [location]);

  const onCreateObjective = useCallback(
    async (roleId: number, text: string) => {
      try {
        await session.createObjective(roleId, text);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  const onUpdateObjective = useCallback(
    async (id: number, text: string) => {
      try {
        await session.updateObjective(id, text);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  const onDeleteObjective = useCallback(
    async (id: number) => {
      try {
        await session.deleteObjective(id);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  const onCreateSalesThreshold = useCallback(
    async (salesThreshold: SalesThreshold, competitionId: number) => {
      try {
        await session.createSalesThreshold(salesThreshold, competitionId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );
  const onUpdateSalesThreshold = useCallback(
    async (salesThreshold: SalesThreshold, competitionId: number) => {
      try {
        await session.updateSalesThreshold(salesThreshold, competitionId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );
  const onDeleteSalesThreshold = useCallback(
    async (salesThresholdId: number) => {
      try {
        await session.deleteSalesThreshold(salesThresholdId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  const onCreateExtraSalesThreshold = useCallback(
    async (extraThresholds: ExtraSalesThreshold, competitionId: number) => {
      try {
        await session.createExtraSalesThreshold(extraThresholds, competitionId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );
  const onUpdateExtraSalesThreshold = useCallback(
    async (extraSalesThreshold: ExtraSalesThreshold, competitionId: number) => {
      try {
        await session.updateExtraSalesThreshold(
          extraSalesThreshold,
          competitionId
        );
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );
  const onDeleteExtraSalesThreshold = useCallback(
    async (extraSalesThresholdId: number) => {
      try {
        await session.deleteExtraSalesThreshold(extraSalesThresholdId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  const onCreateManagementCompetition = useCallback(
    async (
      managementCompetition: ManagementCompetition,
      competitionId: number
    ) => {
      try {
        await session.createManagementCompetition(
          managementCompetition,
          competitionId
        );
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  const onUpdateManagementCompetition = useCallback(
    async (
      managementCompetition: ManagementCompetition,
      competitionId: number
    ) => {
      try {
        await session.updateManagementCompetition(
          managementCompetition,
          competitionId
        );
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );
  const onDeleteManagementCompetition = useCallback(
    async (managementCompetitionId: number) => {
      try {
        await session.deleteManagementCompetition(managementCompetitionId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  const onCreateCompetition = useCallback(
    async (startDate: Date, endDate: Date, roleId: number) => {
      try {
        await session.createCompetition(startDate, endDate, roleId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
        setMessage({
          type: "danger",
          message: t("messages.errors.competitionForm.invalidPeriod"),
        });
      }
    },
    [session, t]
  );

  const onUpdateCompetition = useCallback(
    async (competition: Competition, roleId: number) => {
      try {
        await session.updateCompetition(competition, roleId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
        setMessage({
          type: "danger",
          message: t("messages.errors.competitionForm.invalidPeriod"),
        });
      }
    },
    [session, t]
  );

  const onDeleteCompetition = useCallback(
    async (competitionId: number) => {
      try {
        await session.deleteCompetition(competitionId);
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    },
    [session]
  );

  return (
    <Flex padding={"3%"} flexDirection={"column"}>
      <Wrapper>
        <Sidebar
          items={roles ? roles.map((role) => roleToContactItemType(role)) : []}
          activeId={activeRoleId}
          clickHandler={handleRoleClick}
          disableSections={true}
        />
        <Main
          role={currentRole}
          roleMessage={message}
          onCreateObjective={onCreateObjective}
          onUpdateObjective={onUpdateObjective}
          onDeleteObjective={onDeleteObjective}
          onCreateSalesThreshold={onCreateSalesThreshold}
          onUpdateSalesThreshold={onUpdateSalesThreshold}
          onDeleteSalesThreshold={onDeleteSalesThreshold}
          onCreateExtraSalesThreshold={onCreateExtraSalesThreshold}
          onUpdateExtraSalesThreshold={onUpdateExtraSalesThreshold}
          onDeleteExtraSalesThreshold={onDeleteExtraSalesThreshold}
          onCreateManagementCompetition={onCreateManagementCompetition}
          onUpdateManagementCompetition={onUpdateManagementCompetition}
          onDeleteManagementCompetition={onDeleteManagementCompetition}
          onCreateCompetition={onCreateCompetition}
          onUpdateCompetition={onUpdateCompetition}
          onDeleteCompetition={onDeleteCompetition}
        />
      </Wrapper>
    </Flex>
  );
});
