import { User } from "../../types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Input, FormGroup, Label } from "./../Common/Forms";
import { ErrorText } from "./../Common/Forms/ErrorText";
import { Button } from "./../Common/Button";

type Props = {
  onSubmit: (
    email: string,
    password?: string,
    confirmPassword?: string
  ) => void;
  initialValues?: User;
  isSubmitting?: boolean;
};

export const UserCredentialsForm: React.FC<Props> = ({
  onSubmit,
  initialValues,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(t("messages.errors.credentialsForm.emailRequired")),
    password: Yup.string()
      .min(6, t("messages.formValidations.common.passwordMinLength"))
      .nullable(),
    confirmPassword: Yup.string()
      .min(6, t("messages.formValidations.common.passwordMinLength"))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? {
          email: initialValues.email,
          password: undefined,
          confirmPassword: undefined,
        }
      : {
          email: undefined,
          password: undefined,
          confirmPassword: undefined,
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.email)
        onSubmit(values.email, values.password, values.confirmPassword);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        style={{ display: "flex", flexDirection: "column", minWidth: "20rem" }}
      >
        {/* Email */}
        <FormGroup>
          <Label htmlFor={"email"}>
            {t("components.credentialsForm.email")}
          </Label>
          <Input
            id={"email"}
            name={"email"}
            type={"text"}
            value={formik.values.email || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.credentialsForm.email")}
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorText>{formik.errors.email}</ErrorText>
          )}
        </FormGroup>

        {/* Password */}
        <FormGroup mt={"1rem"}>
          <Label htmlFor={"password"}>
            {t("components.credentialsForm.password")}
          </Label>
          <Input
            id={"password"}
            name={"password"}
            type={"password"}
            value={formik.values.password || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.credentialsForm.password")}
          />
          {formik.touched.password && formik.errors.password && (
            <ErrorText>{formik.errors.password}</ErrorText>
          )}
        </FormGroup>

        <FormGroup mt={"1rem"}>
          <Label htmlFor={"confirmPassword"}>
            {t("components.credentialsForm.confirmPassword")}
          </Label>
          <Input
            id={"confirmPassword"}
            name={"confirmPassword"}
            type={"password"}
            value={formik.values.confirmPassword || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.credentialsForm.confirmPassword")}
          />
          {/* ConfirmPassword */}
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <ErrorText>{formik.errors.confirmPassword}</ErrorText>
          )}
        </FormGroup>

        <div style={{ width: "100%", marginTop: "2rem" }}>
          <Button
            type={"submit"}
            color={"brand2"}
            fontSize={"16px"}
            disabled={isSubmitting}
            width={"100%"}
          >
            {t("common.confirm")}
          </Button>
        </div>
      </div>
    </form>
  );
};
