import {
  Commission,
  ContactItemType,
  Objective,
  Period,
  Practice,
  ProgressCardItemType,
  User,
} from "../types";
import i18n from "../i18n";
import { toCurrency } from "./conversions";
import { formatPercentageToString } from "./methods";

export const phoneRegEx =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const areDatesEqual = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getMonthNameAndYear = (date: Date): string =>
  i18n.t("months." + (date.getMonth() + 1)) + " " + date.getFullYear();

export const isDateBetweenTwoDates = (
  from: Date,
  to: Date,
  date: Date | undefined
): boolean => {
  if (!date) return false;
  let fromDate = new Date(from.getFullYear(), from.getMonth(), from.getDate());
  let toDate = new Date(to.getFullYear(), to.getMonth(), to.getDate());
  let dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  if (toDate >= dateDate && fromDate <= dateDate) {
    return true;
  } else {
    return false;
  }
};

export const getNextMonthDate = (): Date => {
  let date = new Date();
  if (date.getMonth() === 11) {
    return new Date(date.getFullYear() + 1, 0, 1);
  } else {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }
};

export const getPeriodPractices = (
  period: Period,
  practices: Practice[]
): Practice[] => {
  let toReturn: Practice[] = [];

  for (let i = 0; i < practices.length; i++) {
    if (
      isDateBetweenTwoDates(period.fromDate, period.toDate, practices[i].date)
    )
      toReturn.push(practices[i]);
  }

  return toReturn;
};

export const getPracticesInPayment = (
  period: Period,
  practices: Practice[]
): Practice[] => {
  let toReturn: Practice[] = [];

  for (let i = 0; i < practices.length; i++) {
    if (
      practices[i].state === "validated" &&
      practices[i].paymentMonth &&
      isDateBetweenTwoDates(
        period.fromDate,
        period.toDate,
        practices[i].paymentMonth
      )
    )
      toReturn.push(practices[i]);
  }

  return toReturn;
};

export const filterContactItemsByText = (
  text: string,
  contactItems: ContactItemType[]
): ContactItemType[] => {
  return contactItems.filter(
    (contactItem) =>
      contactItem.primaryText.toLowerCase().indexOf(text.toLowerCase()) !==
        -1 ||
      (contactItem.secondaryText &&
        contactItem.secondaryText.toLowerCase().indexOf(text.toLowerCase()) !==
          -1)
  );
};

export const getObjectivesPercentage = (objectives: Objective[]): number => {
  let currentCount = 0;

  if (objectives.length === 0) return 0;

  for (let i = 0; i < objectives.length; i++) {
    if (objectives[i].completed) {
      currentCount++;
    }
  }
  return (currentCount / objectives.length) * 100;
};

export const getUserProgressCards = (
  user: User,
  commissions: Commission
): ProgressCardItemType[][] => {
  if (!commissions) return [];

  let toReturn: ProgressCardItemType[][] = [];

  toReturn = [
    [
      {
        value: toCurrency(commissions.commissionsInPayment),
        name: i18n.t("components.progressCard.inPayment"),
        onHoverText: i18n.t("components.progressCard.inPaymentTooltip"),
        //percentage: 80,
      },
      {
        value: toCurrency(commissions.reversal?.amount || 0),
        name: i18n.t("components.progressCard.reversal"),
        onHoverText: i18n.t("components.progressCard.reversalTooltip"),
        //percentage: 80,
      },
    ],
    [
      {
        value: commissions.sells.toString(),
        name: i18n.t("components.progressCard.sells"),
        //percentage: 80,
      },
    ],
    [
      {
        value: toCurrency(
          commissions.bonuses +
            commissions.totalJuniorBonus +
            commissions.totalSeniorBonus
        ),
        name: i18n.t("components.progressCard.sellsBonus"),
        //percentage: 90,
      },
    ],
    [
      {
        value: toCurrency(commissions.totalCommissions),
        name: i18n.t("components.progressCard.commissions"),
      },
      {
        value: formatPercentageToString(
          user.role.commissionPercentage ? user.role.commissionPercentage : 0,
          1
        ),
        name: i18n.t("components.progressCard.commissionsPercentage"),
      },
    ],
  ];
  /* if (user.role.name === "supervisor" || user.role.name === "area-manager") {
    toReturn.push([
      {
        value: commissions.sells.toString(),
        name: i18n.t("components.progressCard.activeKits"),
      },
      {
        value: formatPercentageToString(user.role.commissionPercentage, 1),
        name: i18n.t("components.progressCard.monthlyBonus"),
      },
    ]);
  } */

  return toReturn;
};
