import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Flex } from "rebass";
import Wrapper from "../../components/AdminManagement/contacts/Wrapper";
import Sidebar from "../../components/AdminManagement/contacts/Sidebar";
import Main from "../../components/AdminManagement/contacts/Main";
import {
  AlertMessage,
  Commission,
  ContactItemType,
  Objective,
  Practice,
  PracticeState,
  Role,
  UpdateOrCreateUserParams,
  User,
} from "../../types";
import { useStores } from "../../hooks/useStores";
import { toUserComplete, userToContactItemType } from "../../utils/conversions";
import UserForm from "../../components/AdminUsersManagement/UserForm";
import { CenteredModal } from "../../components/Common/CenteredModal";
import { t } from "i18next";
import { DeleteModal } from "../../components/Common/DeleteModal";
import dayjs from "dayjs";

export const AdminUsersManagement: React.FC = observer(() => {
  const { session, practices } = useStores();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>(
    session.user ? [session.user] : []
  );
  const [roles, setRoles] = useState<Role[]>([]);
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentPractices, setCurrentPractices] = useState<Practice[]>([]);
  const [currentObjectives, setCurrentObjectives] = useState<Objective[]>([]);
  const [canUpgradeCurrentUserRole, setCanUpgradeCurrentUserRole] =
    useState(false);
  const [canDowngradeCurrentUserRole, setCanDowngradeCurrentUserRole] =
    useState(false);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false);
  const [message, setMessage] = useState<AlertMessage>();

  const [period, setPeriod] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: dayjs().startOf("month").toDate(),
    toDate: dayjs().endOf("month").toDate(),
  });
  const [commissions, setCommissions] = useState<Commission>();

  const activeUserId = useMemo(() => {
    return params.itemId ? Number(params.itemId) : undefined;
  }, [params]);

  const handleUserClick = useCallback(
    (item: ContactItemType) => {
      navigate(`/admin/users-management/${item.id}`);
    },
    [navigate]
  );

  const updateCommissions = useCallback(async () => {
    if (currentUser) {
      const commissionsDate = new Date(period.fromDate);
      commissionsDate.setMonth(commissionsDate.getMonth() + 1);
      setCommissions(
        (await session.getUserCommissions(
          +currentUser.id,
          commissionsDate.getFullYear(),
          commissionsDate.getMonth()
        )) || undefined
      );
    }
  }, [currentUser, period.fromDate, session]);

  const updatePractices = useCallback(async () => {
    const user = users.find((user) => +user.id === activeUserId);
    setCurrentPractices(
      await practices.getUserPractices(user, period.fromDate, period.toDate)
    );
    if (user) setCurrentObjectives(await session.getUserObjectives(+user.id));
    updateCommissions();
  }, [
    users,
    period.fromDate,
    period.toDate,
    activeUserId,
    practices,
    session,
    updateCommissions,
  ]);

  useEffect(() => {
    const changeUser = async () => {
      try {
        const user = users.find((user) => +user.id === activeUserId);
        setCurrentUser(user); //activeId represents the index of the active user in the list
        if (user) {
          updatePractices();
          setCanUpgradeCurrentUserRole(
            await session.canUpdateRole(+user.id, "upgrade")
          );
          setCanDowngradeCurrentUserRole(
            await session.canUpdateRole(+user.id, "downgrade")
          );
        }
      } catch (e) {
        console.log(e);
      }
    };
    changeUser();
  }, [
    activeUserId,
    session.user,
    practices,
    users,
    session,
    period.fromDate,
    period.toDate,
    updatePractices,
  ]);

  useEffect(() => {
    const init = async () => {
      try {
        setUsers(await session.getUsers(true));
        setRoles(await session.getRoles(["objectives", "role"]));
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, [session]);

  useEffect(() => {
    const handleMonthChange = async () => {
      if (currentUser) {
        try {
          updateCommissions();
        } catch (e) {
          console.log(e);
        }
      }
    };
    handleMonthChange();
  }, [currentUser, updateCommissions]);

  useEffect(() => {
    if (location.state && location.state.message) {
      //setMessage(location.state.message);
    }
  }, [location]);

  const onCreatePractice = useCallback(
    async (
      user: User,
      customer: string,
      date: Date,
      totalAmount: number,
      description?: string
    ) => {
      try {
        await practices.createPractice({
          id: -1,
          customer,
          date,
          totalAmount,
          description,
          state: "toValidate",
          user: user,
        });
        updatePractices();
      } catch (e) {
        console.log(e);
      }
    },
    [practices, updatePractices]
  );

  const onUpdatePractice = useCallback(
    async (practiceId: number, state: PracticeState, paymentMonth?: Date) => {
      try {
        await practices.updatePractice(practiceId, {
          state,
          paymentMonth,
        });
        updatePractices();
      } catch (e) {
        console.log(e);
      }
    },
    [practices, updatePractices]
  );

  const onEditCheck = useCallback(
    async (objectiveId: number, completed: boolean) => {
      try {
        const activeUser = users.find((user) => +user.id === activeUserId);
        if (currentUser)
          await session.editObjectiveCheck(
            completed,
            objectiveId,
            +currentUser.id
          );
        if (activeUser)
          setCurrentObjectives(await session.getUserObjectives(+activeUser.id));
      } catch (e) {
        console.log(e);
      }
    },
    [session, users, activeUserId, currentUser]
  );

  const onUpdateUserRole = useCallback(
    async (updateType: "upgrade" | "downgrade") => {
      try {
        if (currentUser)
          await session.updateUserRole(+currentUser.id, updateType);
        setUsers(await session.getUsers(true));
      } catch (e) {
        console.log(e);
      }
    },
    [session, currentUser]
  );

  const onCreateUser = useCallback(
    async (user: UpdateOrCreateUserParams) => {
      try {
        if (!user.usersPermissionsId) {
          user.usersPermissionsId = roles.find(
            (role) => role.id === user.roleId
          )?.usersPermissionsId;
        }

        await session.createUser(user);
        setIsCreateUserModalOpen(false);
        setMessage({
          type: "success",
          message: t("messages.successes.userForm.generic"),
        });
        setUsers(await session.getUsers(true));
      } catch (e) {
        console.log(e);
        setMessage({
          type: "danger",
          message: t("messages.errors.createUserForm.generic"),
        });
      }
    },
    [session, roles]
  );
  const onUpdateUser = useCallback(
    async (user: UpdateOrCreateUserParams) => {
      try {
        if (!user.usersPermissionsId) {
          user.usersPermissionsId = roles.find(
            (role) => role.id === user.roleId
          )?.usersPermissionsId;
        }

        if (currentUser) await session.updateUser(user);
        setIsUpdateUserModalOpen(false);
        setMessage({
          type: "success",
          message: t("messages.successes.updateUserForm.generic"),
        });
        setUsers(await session.getUsers(true));
      } catch (e) {
        console.log(e);
        setMessage({
          type: "danger",
          message: t("messages.errors.updateUserForm.generic"),
        });
      }
    },
    [session, currentUser, roles]
  );

  const onDeleteUser = useCallback(
    async (id: string) => {
      try {
        if (currentUser) await session.deleteUser(id);
        setUsers(await session.getUsers(true));
      } catch (e) {
        console.log(e);
      }
    },
    [currentUser, session]
  );

  return (
    <Flex padding={"3%"} flexDirection={"column"}>
      <Wrapper>
        <Sidebar
          items={users.map((user, index) => userToContactItemType(user))}
          activeId={activeUserId}
          clickHandler={handleUserClick}
          onAddClick={() => setIsCreateUserModalOpen(true)}
        />
        <Main
          message={message}
          user={toUserComplete(currentUser, currentPractices)}
          period={period}
          setPeriod={setPeriod}
          commissions={commissions}
          objectives={currentObjectives}
          onUpdatePractice={onUpdatePractice}
          onCreatePractice={onCreatePractice}
          onEditCheck={onEditCheck}
          onUpdateUserRole={onUpdateUserRole}
          canUpgradeCurrentUserRole={canUpgradeCurrentUserRole}
          canDowngradeCurrentUserRole={canDowngradeCurrentUserRole}
          onDeleteUser={() => setIsDeleteUserModalOpen(true)}
          onUpdateUser={() => setIsUpdateUserModalOpen(true)}
          onFormedUserClick={handleUserClick}
        />
      </Wrapper>
      <CenteredModal
        title={t("components.userModal.createUser")}
        isOpen={isCreateUserModalOpen}
        setIsOpen={setIsCreateUserModalOpen}
      >
        <UserForm
          isSubmitting={session.isSubmitting}
          onSubmit={onCreateUser}
          message={message}
          roles={roles}
          users={users}
        />
      </CenteredModal>

      <CenteredModal
        title={t("components.userModal.updateUser")}
        isOpen={isUpdateUserModalOpen}
        setIsOpen={setIsUpdateUserModalOpen}
      >
        <UserForm
          isSubmitting={session.isSubmitting}
          onSubmit={onUpdateUser}
          message={message}
          user={currentUser}
          roles={roles}
          users={users}
        />
      </CenteredModal>

      <DeleteModal
        title={t("components.userModal.deleteUser")}
        text={t("components.userModal.deleteUserText")}
        isOpen={isDeleteUserModalOpen}
        setIsOpen={setIsDeleteUserModalOpen}
        onDelete={() => currentUser && onDeleteUser(currentUser.id)}
      />
    </Flex>
  );
});
