import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../../hooks/useStores";
import {
  Commission,
  Objective,
  Practice,
  PracticeState,
  ProgressCardItemType,
} from "../../types";
import { DashboardContainer, InternalContainer } from "./style";
import { CareerPlan } from "../../components/Common/CareerPlan";
import { UserPracticesSummary } from "../../components/Common/UserPracticesSummary";
import dayjs from "dayjs";
import { Flex } from "rebass";
import { ProgressCard } from "../../components/Common/ProgressCard";
import {
  canViewFormedJuniors,
  canViewFormedSeniors,
  getFormedJuniorsProgressCardItems,
  getFormedSeniorsProgressCardItems,
} from "../../utils/roles";
import { UsersListModal } from "../../components/Common/UsersListModal";
import { useTranslation } from "react-i18next";
import { getUserProgressCards } from "../../utils/others";

export const CommonDashboard: React.FC = observer(() => {
  const { session, practices } = useStores();
  const location = useLocation();
  const { t } = useTranslation();

  const [isTrainedJuniorsModalOpen, setIsTrainedJuniorsModalOpen] =
    useState(false);
  const [isTrainedSeniorsModalOpen, setIsTrainedSeniorsModalOpen] =
    useState(false);
  const [period, setPeriod] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: dayjs().startOf("month").toDate(),
    toDate: dayjs().endOf("month").toDate(),
  });
  const [currentPractice, setCurrentPractice] = useState<
    Practice | undefined
  >();
  const [currentObjectives, setCurrentObjectives] = useState<Objective[]>([]);
  const [progressCards, setProgressCards] = useState<ProgressCardItemType[][]>([
    [],
  ]);
  const [commissions, setCommissions] = useState<Commission | null>(null);

  const updateCommissions = useCallback(async () => {
    if (session.user) {
      try {
        const commissionsDate = new Date(period.fromDate);
        commissionsDate.setMonth(commissionsDate.getMonth() + 1);

        const tempCommissions = await session.getUserCommissions(
          +session.user.id,
          commissionsDate.getFullYear(),
          commissionsDate.getMonth()
        );
        if (tempCommissions) {
          setCommissions(tempCommissions);
          setProgressCards(getUserProgressCards(session.user, tempCommissions));
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [period.fromDate, session]);

  const updatePractices = useCallback(async () => {
    if (session.user) {
      await practices.getUserPractices(
        undefined,
        period.fromDate,
        period.toDate
      );
      setCurrentObjectives(await session.getUserObjectives(+session.user.id));
      updateCommissions();
    }
  }, [period.fromDate, period.toDate, practices, session, updateCommissions]);

  useEffect(() => {
    const init = async () => {
      try {
        if (session.user) {
          await updatePractices();
          setCurrentObjectives(
            await session.getUserObjectives(+session.user.id)
          );
        }
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, [practices, session.user, session, updatePractices]);

  useEffect(() => {
    const handleMonthChange = async () => {
      await updateCommissions();
    };
    handleMonthChange();
  }, [period.fromDate, session, session.user, updateCommissions]);

  useEffect(() => {
    if (location.state && location.state.message) {
      //setMessage(location.state.message);
    }
  }, [location]);

  const onCreatePractice = useCallback(
    async (
      customer: string,
      date: Date,
      totalAmount: number,
      description?: string
    ) => {
      if (session.user) {
        try {
          await practices.createPractice({
            id: -1,
            customer,
            date,
            totalAmount,
            description,
            state: "toValidate",
            user: session.user,
          });
          await updatePractices();
        } catch (e) {
          console.log(e);
        }
      }
    },
    [practices, session.user, updatePractices]
  );

  const onUpdateCurrentPractice = useCallback(
    async (state: PracticeState, paymentMonth?: Date) => {
      if (session.user && currentPractice) {
        try {
          await practices.updatePractice(currentPractice.id, {
            state,
            paymentMonth,
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    [practices, session.user, currentPractice]
  );

  const onPracticeClick = useCallback((practice: Practice) => {
    setCurrentPractice(practice);
  }, []);

  if (!session.user) return <></>;

  return (
    <DashboardContainer>
      <Flex flexDirection={"column"} mr={"2rem"} minWidth={"12rem"}>
        <CareerPlan
          variant={"complete"}
          role={session.user.role}
          objectives={currentObjectives}
        />
        <div style={{ marginBottom: "1rem" }}>
          {canViewFormedJuniors(session.user) && (
            <ProgressCard
              items={getFormedJuniorsProgressCardItems(
                session.user,
                commissions
              )}
              onClick={() => setIsTrainedJuniorsModalOpen(true)}
            />
          )}
        </div>
        {canViewFormedSeniors(session.user) && (
          <ProgressCard
            items={getFormedSeniorsProgressCardItems(
              session.user,
              commissions || null
            )}
            onClick={() => setIsTrainedSeniorsModalOpen(true)}
          />
        )}
      </Flex>
      <InternalContainer>
        <UserPracticesSummary
          user={session.user}
          practices={practices.currentPractices}
          onCreatePractice={onCreatePractice}
          canCreatePractices={true}
          currentPractice={currentPractice}
          onPracticeClick={onPracticeClick}
          onUpdateCurrentPractice={onUpdateCurrentPractice}
          period={period}
          setPeriod={setPeriod}
          progressItems={progressCards}
        />
      </InternalContainer>
      <UsersListModal
        title={t("roles.formedJuniors")}
        users={session.user.trainedJuniors || []}
        isOpen={isTrainedJuniorsModalOpen}
        setIsOpen={setIsTrainedJuniorsModalOpen}
      />
      <UsersListModal
        title={t("roles.formedSeniors")}
        users={session.user.trainedSeniors || []}
        isOpen={isTrainedSeniorsModalOpen}
        setIsOpen={setIsTrainedSeniorsModalOpen}
      />
    </DashboardContainer>
  );
});
