import styled from "styled-components";
import { Card } from "../../UI/Card";

export const StyledCard = styled(Card)`
  padding: 1.5rem;
  border-radius: 10px;
  justify-content: center;
  height: 10rem;
  min-width: 11rem;
  margin-right: 1rem;
`;
export const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ValueText = styled.p`
  font-size: 25px;
  font-weight: 400;
  margin: 0;
`;

export const NameText = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  color: #1b2e4bb2;
`;
