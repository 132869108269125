import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "rebass";
import { User } from "../../types";
import { toCurrency } from "../../utils/conversions";
import { formatName } from "../../utils/methods";
import { Table, Tbody, Td, Th, Thead, Tr } from "./Table";
import { TextInput } from "./TextInput";

type Props = {
  users: User[];
  onChangeReversal: (amount: number, userId: User["id"], id?: number) => void;
  onUserClick?: (user: User) => void;
};

export const UsersWithCommissionsTable: React.FC<Props> = ({
  users,
  onUserClick,
  onChangeReversal,
}: Props) => {
  const { t } = useTranslation();

  const orderedUsers: User[] = useMemo(
    () => users.sort((a, b) => +a.id - +b.id),
    [users]
  );

  const rows: any[] = useMemo(() => {
    return orderedUsers.map((user, index) => {
      return (
        <Tr
          onClick={(e) => {
            if (onUserClick) {
              onUserClick(user);
            }
          }}
          style={{ cursor: onUserClick ? "pointer" : "default" }}
          key={user.id}
        >
          <Td>{user.id}</Td>
          <Td>{formatName(user.name, user.surname)}</Td>
          <Td>{t("roles." + user.role.name)}</Td>
          <Td>{user.commissions?.sellsInPayment || 0}</Td>
          <Td>{toCurrency(user.commissions?.commissionsInPayment || 0)}</Td>
          <Td>
            {toCurrency(
              user.commissions
                ? user.commissions.bonuses + user.commissions.totalJuniorBonus
                : 0
            )}
          </Td>
          <Td>{toCurrency(user.commissions?.totalCommissions || 0)}</Td>
          <Td>
            <TextInput
              inputType={"currency"}
              value={user.commissions?.reversal?.amount || 0}
              onChange={(value) => {
                onChangeReversal(
                  +value,
                  user.id,
                  user.commissions?.reversal?.id
                );
              }}
            />
          </Td>
          <Td style={{ fontWeight: 500, backgroundColor: "rgba(0,0,0,0.02)" }}>
            {toCurrency(
              (user.commissions?.totalCommissions || 0) -
                (user.commissions?.reversal?.amount || 0)
            )}
          </Td>
        </Tr>
      );
    });
  }, [orderedUsers, t, onUserClick, onChangeReversal]);

  return (
    <Flex width={"100%"} flexDirection={"column"} fontSize={"13px"}>
      <Text
        fontSize={"11px"}
        fontWeight={500}
        style={{ textTransform: "uppercase" }}
      >
        {t("common.users")}
      </Text>
      <Table>
        <Thead>
          <Tr>
            <Th scope="col">{t("components.usersWithCommissionsTable.id")}</Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.name")}
            </Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.role")}
            </Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.sells")}
            </Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.commissions")}
            </Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.bonuses")}
            </Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.totalCommissions")}
            </Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.reversal")}
            </Th>
            <Th scope="col">
              {t("components.usersWithCommissionsTable.toPay")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rows.length > 0 ? rows : <></>}</Tbody>
      </Table>
    </Flex>
  );
};
