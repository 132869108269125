import styled from "styled-components";
import { Brand } from "../../components/Common/Brand";

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2%;
  padding-top: calc(2% + 2rem);
  align-items: center;
`;

export const CredentialsContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UserBrandContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledBrand = styled(Brand)`
  min-width: 10rem;
  min-height: 10rem;
  width: 15vw;
  height: 15vw;
  max-width: 25rem;
  max-height: 25rem;
`;

export const NominativeText = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;
