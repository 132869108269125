import { Practice } from "../../types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Input, FormGroup, Label } from "./Forms";
import { InputGroupAddon, InputGroupText } from "./Forms/InputGroup";
import { ErrorText } from "./Forms/ErrorText";
import { Textarea } from "./Forms/Textarea";
import { Button } from "./Button";
import { dateToDatePickerFormat } from "../../utils/conversions";

type Props = {
  onSubmit?: (
    customer: string,
    date: Date,
    totalAmount: number,
    description?: string
  ) => void;
  onCancel: () => void;
  initialValues?: Practice;
  isSubmitting?: boolean;
};
export const PracticeForm: React.FC<Props> = ({
  onSubmit,
  initialValues,
  isSubmitting,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    customer: Yup.string()
      .required(t("messages.errors.practiceForm.customerRequired"))
      .nullable(),
    date: Yup.date()
      .required(t("messages.errors.practiceForm.dateRequired"))
      .nullable(),
    totalAmount: Yup.number()
      .required(t("messages.errors.practiceForm.totalAmountRequired"))
      .nullable(),
    description: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? {
          customer: initialValues.customer,
          date: dateToDatePickerFormat(initialValues.date),
          totalAmount: initialValues.totalAmount,
          description: initialValues.description,
        }
      : {
          customer: null,
          date: null,
          totalAmount: null,
          description: null,
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (onSubmit && values.customer && values.date && values.totalAmount)
        onSubmit(
          values.customer,
          new Date(values.date),
          values.totalAmount,
          values.description || undefined
        );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Customer */}
        {formik.touched.customer && formik.errors.customer && (
          <ErrorText>{formik.errors.customer}</ErrorText>
        )}
        <FormGroup
          mb={"20px"}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "10%" }}>
            <Label display={"block"} mb={"5px"} htmlFor={"customer"}>
              {t("components.practiceForm.customer")}
            </Label>
          </div>
          <Input
            id={"customer"}
            name={"customer"}
            type={"text"}
            value={formik.values.customer || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.practiceForm.customer")}
            ml={"1rem"}
          />
        </FormGroup>

        {/* Date */}
        {formik.touched.date && formik.errors.date && (
          <ErrorText>{formik.errors.date}</ErrorText>
        )}
        <FormGroup
          mb={"20px"}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "10%" }}>
            <Label mb={"5px"} htmlFor={"date"}>
              {t("components.practiceForm.date")}
            </Label>
          </div>
          <Input
            id={"date"}
            name={"date"}
            type={"date"}
            value={formik.values.date || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.practiceForm.date")}
            ml={"1rem"}
          />
        </FormGroup>

        {/* Total Amount */}
        {formik.touched.totalAmount && formik.errors.totalAmount && (
          <ErrorText>{formik.errors.totalAmount}</ErrorText>
        )}
        <FormGroup
          mb={"20px"}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "10%" }}>
            <Label display={"block"} mb={"5px"} htmlFor={"totalAmount"}>
              {t("components.practiceForm.totalAmount")}
            </Label>
          </div>
          <InputGroupAddon dir="prepend" ml={"1rem"}>
            <InputGroupText>€</InputGroupText>
          </InputGroupAddon>
          <Input
            id={"totalAmount"}
            name={"totalAmount"}
            type={"text"}
            value={formik.values.totalAmount || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.practiceForm.totalAmount")}
          />
        </FormGroup>

        {/* Description */}
        {formik.touched.description && formik.errors.description && (
          <ErrorText>{formik.errors.description}</ErrorText>
        )}
        <FormGroup mb={"20px"}>
          <Label display={"block"} mb={"5px"} htmlFor={"description"}>
            {t("components.practiceForm.description")}
          </Label>
          <Textarea
            id={"description"}
            name={"description"}
            value={formik.values.description || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.practiceForm.description")}
          />
        </FormGroup>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant={"outlined"}
            fontSize={"16px"}
            disabled={isSubmitting}
            mr={"1rem"}
            onClick={onCancel}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type={"submit"}
            color={"brand2"}
            fontSize={"16px"}
            disabled={isSubmitting}
          >
            {t("common.confirm")}
          </Button>
        </div>
      </div>
    </form>
  );
};
