import styled from "styled-components";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2%;
  padding-top: 3rem;
  align-items: flex-start;
  overflow: auto;
  max-width: 100vw;
`;

export const InternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 65vw;
`;
