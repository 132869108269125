import { useCallback, useEffect, useState } from "react";
import Content from "../../components/Common/Content";
import Signin from "../../components/Auth/Signin";
import { useLocation, useNavigate } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react";
import { AlertMessage } from "../../types";
import { t } from "i18next";

export const SignInScreen: React.FC = observer(() => {
  const { session } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  const [message, setMessage] = useState<AlertMessage>();

  const handleSubmit = useCallback(
    async (email: string, password: string) => {
      try {
        await session.login(email, password);
        navigate("/");
      } catch (err) {
        setMessage({
          message: t("messages.errors.signinForm.wrongCredentials"),
          type: "danger",
        });
      }
    },
    [session, navigate]
  );

  useEffect(() => {
    if (
      session.isLogged &&
      session.user &&
      session.user.role.name !== "no_role"
    ) {
      navigate("/");
    }
    if (location.state && location.state.message) {
      setMessage(location.state.message);
    }
  }, [location, navigate, session.isLogged, session.user]);

  return (
    <Content fullHeight>
      <Signin
        onSubmit={handleSubmit}
        isSubmitting={session.isSubmitting}
        message={message}
      />
    </Content>
  );
});
