import { observer } from "mobx-react";

import { useStores } from "../../hooks/useStores";
import { adminMenu, juniorMenu } from "../../config/menu";
import { Drawer } from "../../components/Common/Drawer";
import { formatName } from "../../utils/methods";
import { ExternalFlex, LogoText, UserText } from "./styled";
import { Brand } from "../../components/Common/Brand";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { Flex } from "rebass";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../components/Common/Dropdown";
import { useTranslation } from "react-i18next";

export const Topbar: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const redirectToProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const redirectToHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogout = useCallback(() => {
    session.resetSession();
    redirectToHome();
  }, [session, redirectToHome]);

  if (!session.user) return <></>;

  return (
    <ExternalFlex>
      <Drawer
        menu={
          session.user && session.user.role.id === "1" ? adminMenu : juniorMenu
        }
      />
      <Flex onClick={redirectToHome} style={{ cursor: "pointer" }}>
        <Brand style={{ marginLeft: "1rem" }} />
        <LogoText style={{ marginLeft: "0.3rem" }}>
          {t("common.title")}
        </LogoText>
      </Flex>
      <Flex ml={"auto"} style={{ cursor: "pointer" }}>
        <Dropdown direction={"down"}>
          <DropdownToggle variant={"texted"}>
            <UserText style={{ marginLeft: "auto" }}>
              {session.user &&
                formatName(session.user.name, session.user.surname)}
            </UserText>
            <Brand
              color={session.user.color}
              style={{ marginLeft: "0.3rem" }}
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={redirectToProfile}>
              {t("common.profile")}
            </DropdownItem>
            <DropdownItem onClick={onLogout}>{t("common.logout")}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Flex>
    </ExternalFlex>
  );
});
