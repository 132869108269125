import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useStores } from "../../hooks/useStores";

type Props = {
  rolesNames?: string[];
};

export const PrivateRoute: React.FC<Props> = ({ rolesNames }: Props) => {
  const { session } = useStores();

  return session.isLogged &&
    (!rolesNames ||
      (session.user && rolesNames.indexOf(session.user?.role.name) !== -1)) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
