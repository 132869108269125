import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { Flex } from "rebass";
import { ManagementCompetition } from "../../types";
import { toCurrency } from "../../utils/conversions";
import { Tr, Td, Table, Thead, Th, Tbody } from "../Common/Table";

type Props = {
  managementCompetitions: ManagementCompetition[];
  onUpdateManagementCompetition?: (id: number) => any;
  onDeleteManagementCompetition?: (id: number) => any;
};
export const ManagementCompetitionTable: React.FC<Props> = ({
  managementCompetitions,
  onUpdateManagementCompetition,
  onDeleteManagementCompetition,
}: Props) => {
  const { t } = useTranslation();

  const rows: any[] = useMemo(() => {
    let orderedManagementCompetitions = managementCompetitions.sort(
      (a, b) => a.monthsAfterCreation - b.monthsAfterCreation
    );
    return orderedManagementCompetitions.map((managementCompetition) => {
      return (
        <Tr key={managementCompetition.id}>
          <Td>{managementCompetition.monthsAfterCreation}</Td>
          <Td>{managementCompetition.juniorClosedContracts}</Td>
          <Td>{toCurrency(managementCompetition.bonus)}</Td>
          <Td>
            <HiOutlinePencil
              style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
              onClick={() =>
                onUpdateManagementCompetition &&
                onUpdateManagementCompetition(managementCompetition.id)
              }
            />
            <HiOutlineTrash
              style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
              onClick={() =>
                onDeleteManagementCompetition &&
                onDeleteManagementCompetition(managementCompetition.id)
              }
            />
          </Td>
        </Tr>
      );
    });
  }, [
    managementCompetitions,
    onUpdateManagementCompetition,
    onDeleteManagementCompetition,
  ]);

  return (
    <Flex width={"100%"} flexDirection={"column"} fontSize={"13px"}>
      <Table>
        <Thead>
          <Tr>
            <Th scope="col">
              {t("components.managementCompetitionTable.monthsAfterCreation")}
            </Th>
            <Th scope="col">
              {t("components.managementCompetitionTable.juniorClosedContracts")}
            </Th>
            <Th scope="col">
              {t("components.managementCompetitionTable.bonus")}
            </Th>
            <Th scope="col">
              {t("components.managementCompetitionTable.action")}
            </Th>
          </Tr>
        </Thead>
        <Tbody style={{ fontSize: "15px" }}>
          {rows.length > 0 ? rows : <></>}
        </Tbody>
      </Table>
    </Flex>
  );
};
