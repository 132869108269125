import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil, HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import { Flex, Text } from "rebass";
import {
  Competition,
  ExtraSalesThreshold,
  ManagementCompetition,
  Role,
  SalesThreshold,
} from "../../../types";
import { toFormattedDate } from "../../../utils/conversions";
import { getRoleActiveCompetition } from "../../../utils/roles";
import { Button } from "../../Common/Button";
import { CalendarNavButtons } from "../../Common/CalendarNavButtons";
import { CheckElement } from "../../Common/CareerPlan";
import { CenteredModal } from "../../Common/CenteredModal";
import { DeleteModal } from "../../Common/DeleteModal";
import { ManagementCompetitionForm } from "../../Common/ManagementCompetitionForm";
import { ObjectiveForm } from "../../Common/ObjectiveForm";
import { SalesThresholdForm } from "../../Common/SalesThresholdForm";
import { StyledPeriod } from "../../Common/StyledPeriod";
import { Card } from "../../UI/Card";
import { CompetitionForm } from "../CompetitionForm";
import { ManagementCompetitionTable } from "../ManagementCompetitionTable";
import { ThresholdTable } from "../ThresholdTable";
import {
  RoleSummaryContainer,
  SectionContainer,
  SectionTitle,
  SectionTitleContainer,
} from "./style";

type Props = {
  role: Role;
  onCreateObjective?: (roleId: number, text: string) => any;
  onUpdateObjective?: (id: number, text: string) => any;
  onDeleteObjective?: (id: number) => any;
  onCreateSalesThreshold?: (
    salesThreshold: SalesThreshold,
    roleId: number
  ) => any;
  onUpdateSalesThreshold?: (
    salesThreshold: SalesThreshold,
    roleId: number
  ) => any;
  onDeleteSalesThreshold?: (id: number) => any;
  onCreateExtraSalesThreshold?: (
    extraSalesThreshold: ExtraSalesThreshold,
    roleId: number
  ) => any;
  onUpdateExtraSalesThreshold?: (
    extraSalesThreshold: ExtraSalesThreshold,
    roleId: number
  ) => any;
  onDeleteExtraSalesThreshold?: (id: number) => any;
  onCreateManagementCompetition?: (
    managementCompetition: ManagementCompetition,
    roleId: number
  ) => any;
  onUpdateManagementCompetition?: (
    managementCompetition: ManagementCompetition,
    roleId: number
  ) => any;
  onDeleteManagementCompetition?: (id: number) => any;
  onCreateCompetition?: (startDate: Date, endDate: Date, roleId: number) => any;
  onUpdateCompetition?: (competition: Competition, roleId: number) => any;
  onDeleteCompetition?: (id: number) => any;
};

type SectionProps = {
  title: string;
  children?: any;
  style?: React.CSSProperties;
  onCreate?: () => void;
};

export const RoleSummary: React.FC<Props> = ({
  role,
  onCreateObjective,
  onUpdateObjective,
  onDeleteObjective,
  onCreateSalesThreshold,
  onUpdateSalesThreshold,
  onDeleteSalesThreshold,
  onCreateExtraSalesThreshold,
  onUpdateExtraSalesThreshold,
  onDeleteExtraSalesThreshold,
  onCreateManagementCompetition,
  onUpdateManagementCompetition,
  onDeleteManagementCompetition,
  onCreateCompetition,
  onUpdateCompetition,
  onDeleteCompetition,
}: Props) => {
  const { t } = useTranslation();

  const [period, setPeriod] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: dayjs().startOf("month").toDate(),
    toDate: dayjs().endOf("month").toDate(),
  });
  const [isDeleteCompetitionModalOpen, setIsDeleteCompetitionModalOpen] =
    useState(false);
  const [isCreateObjectiveModalOpen, setIsCreateObjectiveModalOpen] =
    useState(false);

  const [isCreateSalesThresholdModalOpen, setIsCreateSalesThresholdModalOpen] =
    useState(false);
  const [isUpdateSalesThresholdModalOpen, setIsUpdateSalesThresholdModalOpen] =
    useState(false);
  const [currentThresholdId, setCurrentThresholdId] = useState(0);

  const [
    isCreateExtraSalesThresholdModalOpen,
    setIsCreateExtraSalesThresholdModalOpen,
  ] = useState(false);
  const [
    isUpdateExtraSalesThresholdModalOpen,
    setIsUpdateExtraSalesThresholdModalOpen,
  ] = useState(false);
  const [currentExtraThresholdId, setCurrentExtraThresholdId] = useState(0);

  const [
    isCreateManagementCompetitionModalOpen,
    setIsCreateManagementCompetitionModalOpen,
  ] = useState(false);
  const [
    isUpdateManagementCompetitionModalOpen,
    setIsUpdateManagementCompetitionModalOpen,
  ] = useState(false);
  const [currentManagementCompetitionId, setCurrentManagementCompetitionId] =
    useState(0);

  const [isCreateCompetitionModalOpen, setIsCreateCompetitionModalOpen] =
    useState(false);
  const [isUpdateCompetitionModalOpen, setIsUpdateCompetitionModalOpen] =
    useState(false);

  const currentCompetition = useMemo(() => {
    return getRoleActiveCompetition(role, period.fromDate, period.toDate);
  }, [role, period]);

  const handleCreateObjective = useCallback(
    (text: string) => {
      setIsCreateObjectiveModalOpen(false);
      onCreateObjective && onCreateObjective(+role.id, text);
    },
    [onCreateObjective, role.id]
  );

  const handleCreateSalesThreshold = useCallback(
    (quantity: number, bonus: number) => {
      setIsCreateSalesThresholdModalOpen(false);
      currentCompetition &&
        onCreateSalesThreshold &&
        onCreateSalesThreshold(
          { id: -1, quantity, bonus },
          +currentCompetition.id
        );
    },
    [onCreateSalesThreshold, currentCompetition]
  );

  const handleUpdateSalesThreshold = useCallback(
    (salesThreshold: SalesThreshold) => {
      setIsUpdateSalesThresholdModalOpen(false);
      currentCompetition &&
        onUpdateSalesThreshold &&
        onUpdateSalesThreshold(salesThreshold, +currentCompetition.id);
    },
    [onUpdateSalesThreshold, currentCompetition]
  );

  const handleCreateExtraSalesThreshold = useCallback(
    (quantity: number, bonus: number) => {
      setIsCreateExtraSalesThresholdModalOpen(false);
      currentCompetition &&
        onCreateExtraSalesThreshold &&
        onCreateExtraSalesThreshold(
          { id: -1, quantity, bonus },
          +currentCompetition.id
        );
    },
    [onCreateExtraSalesThreshold, currentCompetition]
  );

  const handleUpdateExtraSalesThreshold = useCallback(
    (extraSalesThreshold: ExtraSalesThreshold) => {
      setIsUpdateExtraSalesThresholdModalOpen(false);
      currentCompetition &&
        onUpdateExtraSalesThreshold &&
        onUpdateExtraSalesThreshold(
          extraSalesThreshold,
          +currentCompetition.id
        );
    },
    [onUpdateExtraSalesThreshold, currentCompetition]
  );

  const handleCreateManagementCompetition = useCallback(
    (
      juniorClosedContracts: number,
      bonus: number,
      monthsAfterCreation: number
    ) => {
      setIsCreateManagementCompetitionModalOpen(false);
      currentCompetition &&
        onCreateManagementCompetition &&
        onCreateManagementCompetition(
          { id: -1, juniorClosedContracts, monthsAfterCreation, bonus },
          +currentCompetition.id
        );
    },
    [onCreateManagementCompetition, currentCompetition]
  );

  const handleUpdateManagementCompetition = useCallback(
    (managementCompetition: ManagementCompetition) => {
      setIsUpdateManagementCompetitionModalOpen(false);
      currentCompetition &&
        onUpdateManagementCompetition &&
        onUpdateManagementCompetition(
          managementCompetition,
          +currentCompetition.id
        );
    },
    [onUpdateManagementCompetition, currentCompetition]
  );

  const handleCreateCompetition = useCallback(
    (startDate: Date, endDate: Date) => {
      setIsCreateCompetitionModalOpen(false);
      onCreateCompetition && onCreateCompetition(startDate, endDate, +role.id);
    },
    [onCreateCompetition, role.id]
  );

  const handleUpdateCompetition = useCallback(
    (startDate: Date, endDate: Date) => {
      setIsUpdateCompetitionModalOpen(false);
      currentCompetition &&
        onUpdateCompetition &&
        onUpdateCompetition(
          { ...currentCompetition, startDate, endDate },
          +role.id
        );
    },
    [onUpdateCompetition, currentCompetition, role.id]
  );

  const handleDeleteCompetition = useCallback(() => {
    currentCompetition &&
      onDeleteCompetition &&
      onDeleteCompetition(+currentCompetition.id);
  }, [onDeleteCompetition, currentCompetition]);

  return (
    <RoleSummaryContainer>
      <RoleSection
        title={t("components.roleSummary.checklist")}
        onCreate={() => setIsCreateObjectiveModalOpen(true)}
        style={{ maxWidth: "60rem" }}
      >
        <div>
          {role.objectives &&
            role.objectives.map((objective) => (
              <CheckElement
                objective={objective}
                key={objective.id}
                editable={true}
                onUpdateObjective={onUpdateObjective}
                onDeleteObjective={onDeleteObjective}
              />
            ))}
        </div>
      </RoleSection>
      <Flex alignItems={"center"} marginBottom={"1rem"}>
        <CalendarNavButtons onChange={setPeriod} />
        <StyledPeriod period={period} />
      </Flex>
      {currentCompetition ? (
        <Card padding={"2rem"} mb={"2rem"} maxWidth={"60rem"}>
          <legend
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            {t("components.roleSummary.currentCompetition")} (
            {toFormattedDate(currentCompetition.startDate) +
              " - " +
              toFormattedDate(currentCompetition.endDate)}
            )
            <HiOutlinePencil
              style={{ width: "1rem", height: "1rem", cursor: "pointer" }}
              onClick={() => setIsUpdateCompetitionModalOpen(true)}
            />
            <HiOutlineTrash
              style={{ width: "1rem", height: "1rem", cursor: "pointer" }}
              onClick={() => setIsDeleteCompetitionModalOpen(true)}
            />
          </legend>

          <RoleSection
            title={t("components.thresholdTable.sectionTitle")}
            onCreate={() => setIsCreateSalesThresholdModalOpen(true)}
          >
            <ThresholdTable
              thresholds={currentCompetition?.thresholds || []}
              onUpdateSalesThreshold={(id: number) => {
                setCurrentThresholdId(id);
                setIsUpdateSalesThresholdModalOpen(true);
              }}
              onDeleteSalesThreshold={onDeleteSalesThreshold}
            />
          </RoleSection>
          <RoleSection
            title={t("components.extraThresholdTable.sectionTitle")}
            onCreate={() => setIsCreateExtraSalesThresholdModalOpen(true)}
          >
            <ThresholdTable
              thresholds={currentCompetition?.extraThresholds || []}
              onUpdateSalesThreshold={(id: number) => {
                setCurrentExtraThresholdId(id);
                setIsUpdateExtraSalesThresholdModalOpen(true);
              }}
              onDeleteSalesThreshold={onDeleteExtraSalesThreshold}
            />
          </RoleSection>
          <RoleSection
            title={t("components.managementCompetitionTable.sectionTitle")}
            onCreate={() => setIsCreateManagementCompetitionModalOpen(true)}
          >
            <ManagementCompetitionTable
              managementCompetitions={
                currentCompetition?.managementCompetitions || []
              }
              onUpdateManagementCompetition={(id: number) => {
                setCurrentManagementCompetitionId(id);
                setIsUpdateManagementCompetitionModalOpen(true);
              }}
              onDeleteManagementCompetition={onDeleteManagementCompetition}
            />
          </RoleSection>
        </Card>
      ) : (
        <Card
          padding={"2rem"}
          mb={"2rem"}
          maxWidth={"60rem"}
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontSize: "17px",
          }}
        >
          <Text>{t("components.roleSummary.noCompetition")}</Text>
          <Button
            color={"brand2"}
            fontSize={"17px"}
            marginTop={"1rem"}
            onClick={() => setIsCreateCompetitionModalOpen(true)}
          >
            {t("components.roleSummary.createCompetition")}
          </Button>
        </Card>
      )}

      {isCreateCompetitionModalOpen && (
        <CenteredModal
          title={t("components.competitionForm.title")}
          isOpen={isCreateCompetitionModalOpen}
          setIsOpen={setIsCreateCompetitionModalOpen}
        >
          <CompetitionForm
            onSubmit={handleCreateCompetition}
            onCancel={() => setIsCreateCompetitionModalOpen(false)}
            initialValues={{
              startDate: period.fromDate,
              endDate: period.toDate,
            }}
          />
        </CenteredModal>
      )}

      {isCreateObjectiveModalOpen && (
        <CenteredModal
          title={t("components.objectiveForm.title")}
          isOpen={isCreateObjectiveModalOpen}
          setIsOpen={setIsCreateObjectiveModalOpen}
        >
          <ObjectiveForm
            onSubmit={handleCreateObjective}
            onCancel={() => setIsCreateObjectiveModalOpen(false)}
          />
        </CenteredModal>
      )}

      {isCreateSalesThresholdModalOpen && (
        <CenteredModal
          title={t("components.salesThresholdForm.title")}
          isOpen={isCreateSalesThresholdModalOpen}
          setIsOpen={setIsCreateSalesThresholdModalOpen}
        >
          <SalesThresholdForm
            onSubmit={handleCreateSalesThreshold}
            onCancel={() => setIsCreateSalesThresholdModalOpen(false)}
          />
        </CenteredModal>
      )}
      {isUpdateSalesThresholdModalOpen && (
        <CenteredModal
          title={t("components.salesThresholdForm.title")}
          isOpen={isUpdateSalesThresholdModalOpen}
          setIsOpen={setIsUpdateSalesThresholdModalOpen}
        >
          <SalesThresholdForm
            onSubmit={(quantity, bonus) =>
              handleUpdateSalesThreshold({
                id: currentThresholdId,
                quantity: quantity,
                bonus: bonus,
              })
            }
            initialValues={(currentCompetition?.thresholds || []).find(
              (threshold) => threshold.id === currentThresholdId
            )}
            onCancel={() => setIsUpdateSalesThresholdModalOpen(false)}
          />
        </CenteredModal>
      )}
      {isCreateExtraSalesThresholdModalOpen && (
        <CenteredModal
          title={t("components.salesThresholdForm.title")}
          isOpen={isCreateExtraSalesThresholdModalOpen}
          setIsOpen={setIsCreateExtraSalesThresholdModalOpen}
        >
          <SalesThresholdForm
            onSubmit={handleCreateExtraSalesThreshold}
            onCancel={() => setIsCreateExtraSalesThresholdModalOpen(false)}
          />
        </CenteredModal>
      )}
      {isUpdateCompetitionModalOpen && currentCompetition?.id && (
        <CenteredModal
          title={t("components.competitionForm.title")}
          isOpen={isUpdateCompetitionModalOpen}
          setIsOpen={setIsUpdateCompetitionModalOpen}
        >
          <CompetitionForm
            onSubmit={handleUpdateCompetition}
            onCancel={() => setIsUpdateCompetitionModalOpen(false)}
            initialValues={{
              startDate: currentCompetition.startDate,
              endDate: currentCompetition.endDate,
            }}
          />
        </CenteredModal>
      )}
      {isUpdateExtraSalesThresholdModalOpen && (
        <CenteredModal
          title={t("components.salesThresholdForm.title")}
          isOpen={isUpdateExtraSalesThresholdModalOpen}
          setIsOpen={setIsUpdateExtraSalesThresholdModalOpen}
        >
          <SalesThresholdForm
            onSubmit={(quantity, bonus) =>
              handleUpdateExtraSalesThreshold({
                id: currentExtraThresholdId,
                quantity,
                bonus,
              })
            }
            initialValues={(currentCompetition?.extraThresholds || []).find(
              (threshold) => threshold.id === currentExtraThresholdId
            )}
            onCancel={() => setIsUpdateExtraSalesThresholdModalOpen(false)}
          />
        </CenteredModal>
      )}
      {isCreateManagementCompetitionModalOpen && (
        <CenteredModal
          title={t("components.managementCompetitionForm.title")}
          isOpen={isCreateManagementCompetitionModalOpen}
          setIsOpen={setIsCreateManagementCompetitionModalOpen}
        >
          <ManagementCompetitionForm
            onSubmit={handleCreateManagementCompetition}
            onCancel={() => setIsCreateManagementCompetitionModalOpen(false)}
          />
        </CenteredModal>
      )}
      {isUpdateManagementCompetitionModalOpen && (
        <CenteredModal
          title={t("components.managementCompetitionForm.title")}
          isOpen={isUpdateManagementCompetitionModalOpen}
          setIsOpen={setIsUpdateManagementCompetitionModalOpen}
        >
          <ManagementCompetitionForm
            onSubmit={(juniorClosedContracts, bonus, monthsAfterCreation) =>
              handleUpdateManagementCompetition({
                id: currentManagementCompetitionId,
                juniorClosedContracts,
                monthsAfterCreation,
                bonus,
              })
            }
            initialValues={(
              currentCompetition?.managementCompetitions || []
            ).find(
              (managementCompetition) =>
                managementCompetition.id === currentManagementCompetitionId
            )}
            onCancel={() => setIsUpdateManagementCompetitionModalOpen(false)}
          />
        </CenteredModal>
      )}
      <DeleteModal
        title={t("components.competitionForm.title")}
        text={t("components.competitionForm.deleteCompetitionText")}
        isOpen={isDeleteCompetitionModalOpen}
        setIsOpen={setIsDeleteCompetitionModalOpen}
        onDelete={handleDeleteCompetition}
      />
    </RoleSummaryContainer>
  );
};

export const RoleSection: React.FC<SectionProps> = ({
  title,
  children,
  onCreate,
  style,
}: SectionProps) => {
  return (
    <SectionContainer style={style}>
      <SectionTitleContainer>
        <SectionTitle>{title}</SectionTitle>
        <Button onClick={onCreate} size={"md"} ml={"auto"} iconButton>
          <HiOutlinePlus />
        </Button>
      </SectionTitleContainer>
      {children}
    </SectionContainer>
  );
};
