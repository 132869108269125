import i18n from "../i18n";
import {
  Commission,
  Competition,
  ProgressCardItemType,
  Role,
  User,
} from "../types";
import { toCurrency } from "./conversions";

export const canViewFormedJuniors = (user: User): boolean => {
  const validRolesNames = ["admin", "senior", "supervisor", "area-manager"];

  if (validRolesNames.includes(user.role.name)) return true;
  return false;
};
export const canViewFormedSeniors = (user: User): boolean => {
  const validRolesNames = ["admin", "area-manager", "supervisor"];

  if (validRolesNames.includes(user.role.name)) return true;
  return false;
};

export const getFormedJuniorsProgressCardItems = (
  user: User,
  commissions: Commission | null
): ProgressCardItemType[] => {
  let toReturn: ProgressCardItemType[] = [];

  toReturn = [
    {
      value: user.trainedJuniors ? user.trainedJuniors.length.toString() : "0",
      name: i18n.t("roles.formedJuniors"),
    },
    {
      value: toCurrency(commissions ? commissions.totalJuniorBonus : 0),
      name: i18n.t("roles.acceditedBonuses"),
    },
  ];

  return toReturn;
};

export const getFormedSeniorsProgressCardItems = (
  user: User,
  commissions: Commission | null
): ProgressCardItemType[] => {
  let toReturn: ProgressCardItemType[] = [];

  toReturn = [
    {
      value: user.trainedSeniors ? user.trainedSeniors.length.toString() : "0",
      name: i18n.t("roles.formedSeniors"),
    },
    {
      value: toCurrency(commissions ? commissions.totalSeniorBonus : 0),
      name: i18n.t("roles.acceditedBonuses"),
    },
  ];

  return toReturn;
};

export const getRoleActiveCompetition = (
  role: Role,
  startDate: Date,
  endDate: Date
): Competition | null => {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  startDate.setDate(1);
  endDate.setDate(1);

  const activeCompetition = role.competitions.find(
    (competition: Competition) => {
      competition.startDate.setHours(0, 0, 0, 0);
      competition.endDate.setHours(0, 0, 0, 0);

      return (
        competition.startDate.getTime() <= startDate.getTime() &&
        competition.endDate.getTime() >= endDate.getTime()
      );
    }
  );

  return activeCompetition || null;
};
