import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { dateToMonthPickerFormat } from "../../utils/conversions";
import { FormGroup, Label, Input } from "../Common/Forms";
import { ErrorText } from "../Common/Forms/ErrorText";
import { Button } from "../Common/Button";

type Props = {
  onSubmit: (startDate: Date, endDate: Date) => void;
  onCancel: () => void;
  initialValues?: { startDate?: Date; endDate?: Date };
  isSubmitting?: boolean;
  error?: string;
};
export const CompetitionForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  initialValues,
  isSubmitting,
  error,
}: Props) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .required(t("messages.errors.competitionForm.startDateRequired"))
      .nullable(),
    endDate: Yup.date()
      .required(t("messages.errors.competitionForm.endDateRequired"))
      .nullable(),
  });
  const formik = useFormik({
    initialValues: initialValues
      ? {
          startDate: initialValues.startDate
            ? dateToMonthPickerFormat(initialValues.startDate)
            : null,
          endDate: initialValues.endDate
            ? dateToMonthPickerFormat(initialValues.endDate)
            : null,
          //queste date non vengono prese dal datepicker, sistemare
        }
      : {
          startDate: null,
          endDate: null,
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.startDate && values.endDate)
        onSubmit(new Date(values.startDate), new Date(values.endDate));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* StartDate */}
        {formik.touched.startDate && formik.errors.startDate && (
          <ErrorText>{formik.errors.startDate}</ErrorText>
        )}
        <FormGroup
          mb={"20px"}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "30%" }}>
            <Label display={"block"} mb={"5px"} htmlFor={"startDate"}>
              {t("components.competitionForm.startDate")}
            </Label>
          </div>
          <Input
            id={"startDate"}
            name={"startDate"}
            type={"month"}
            value={formik.values.startDate || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.competitionForm.startDate")}
            ml={"1rem"}
          />
        </FormGroup>

        {/* EndDate */}
        {formik.touched.endDate && formik.errors.endDate && (
          <ErrorText>{formik.errors.endDate}</ErrorText>
        )}
        <FormGroup
          mb={"20px"}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "30%" }}>
            <Label mb={"5px"} htmlFor={"endDate"}>
              {t("components.competitionForm.endDate")}
            </Label>
          </div>
          <Input
            id={"endDate"}
            name={"endDate"}
            type={"month"}
            value={formik.values.endDate || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.competitionForm.endDate")}
            ml={"1rem"}
          />
        </FormGroup>

        {error && <ErrorText>{error}</ErrorText>}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant={"outlined"}
            fontSize={"16px"}
            disabled={isSubmitting}
            mr={"1rem"}
            onClick={onCancel}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type={"submit"}
            color={"brand2"}
            fontSize={"16px"}
            disabled={isSubmitting}
          >
            {t("common.confirm")}
          </Button>
        </div>
      </div>
    </form>
  );
};
