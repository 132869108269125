import styled from "../../../../styled";
import { Brand } from "../../../Common/Brand";

export const StyledBar = styled.div`
  margin-bottom: 25px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export const StyledBtnWrap = styled.div`
  display: flex;
`;

export const StyledBrand = styled(Brand)`
  min-width: 8rem;
  min-height: 8rem;
  width: 10vw;
  height: 10vw;
  max-width: 20rem;
  max-height: 20rem;
`;
