import styled from "styled-components";

export const ExternalFlex = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: #ffffff;
  height: 3rem;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  z-index: 5;
`;

export const UserText = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const LogoText = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;
