import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { CalendarNavButtons } from "../../components/Common/CalendarNavButtons";
import { Flex } from "rebass";
import dayjs from "dayjs";
//import { Button } from "../../components/Common/Button";
import { useTranslation } from "react-i18next";
import { PracticesTable } from "../../components/Common/PracticesTable";
import { CenteredModal } from "../../components/Common/CenteredModal";
import { useStores } from "../../hooks/useStores";
import { PracticeForm } from "../../components/Common/PracticeForm";
import { PracticeSummary } from "../../components/Common/PracticeSummary";
import { Practice, PracticeState } from "../../types";
import { StyledPeriod } from "../../components/Common/StyledPeriod";
import { canViewFormedJuniors } from "../../utils/roles";

export const AdminPractices: React.FC = observer(() => {
  const { session, practices } = useStores();
  const { t } = useTranslation();
  const location = useLocation();

  const [period, setPeriod] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: dayjs().startOf("month").toDate(),
    toDate: dayjs().endOf("month").toDate(),
  });
  const [currentPractice, setCurrentPractice] = useState<
    Practice | undefined
  >();
  const [isCreatePracticeModalOpen, setIsCreatePracticeModalOpen] =
    useState(false);
  const [isUpdatePracticeModalOpen, setIsUpdatePracticeModalOpen] =
    useState(false);
  //const [message, setMessage] = useState<AlertMessage>();

  useEffect(() => {
    const init = async () => {
      try {
        await practices.getUserPractices(
          undefined,
          period.fromDate,
          period.toDate
        );
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, [practices, period.fromDate, period.toDate]);

  useEffect(() => {
    if (location.state && location.state.message) {
      //setMessage(location.state.message);
    }
  }, [location]);

  const updatePractices = useCallback(async () => {
    await practices.getUserPractices(undefined, period.fromDate, period.toDate);
  }, [practices, period.fromDate, period.toDate]);

  const onCreatePractice = useCallback(
    async (
      customer: string,
      date: Date,
      totalAmount: number,
      description?: string
    ) => {
      if (session.user) {
        try {
          await practices.createPractice({
            id: -1,
            customer,
            date,
            totalAmount,
            description,
            state: "toValidate",
            user: session.user,
          });
          updatePractices();
        } catch (e) {
          console.log(e);
        }
      }
      setIsCreatePracticeModalOpen(false);
    },
    [practices, session.user, updatePractices]
  );

  const onUpdateCurrentPractice = useCallback(
    async (state: PracticeState, paymentMonth?: Date) => {
      if (session.user && currentPractice) {
        try {
          await practices.updatePractice(currentPractice.id, {
            state,
            paymentMonth,
          });
          setIsUpdatePracticeModalOpen(false);
          updatePractices();
        } catch (e) {
          console.log(e);
        }
      }
      setIsCreatePracticeModalOpen(false);
    },
    [practices, session.user, currentPractice, updatePractices]
  );

  const onPracticeClick = useCallback((practice: Practice) => {
    setCurrentPractice(practice);
    setIsUpdatePracticeModalOpen(true);
  }, []);

  if (!session.user) return <></>;

  return (
    <Flex padding={"3%"} flexDirection={"column"}>
      <Flex flexDirection={"row"} alignItems={"center"} width={"100%"}>
        <Flex alignItems={"center"}>
          <CalendarNavButtons onChange={setPeriod} />
          <StyledPeriod period={period} />
        </Flex>
        {/* <Flex flexGrow={1} justifyContent={"flex-end"}>
          <Button
            fontSize={"16px"}
            onClick={() => setIsCreatePracticeModalOpen(true)}
          >
            {t("common.addPractice")}
          </Button>
        </Flex> */}
      </Flex>
      <Flex marginTop={"2rem"} width={"100%"}>
        <PracticesTable
          practices={practices.currentPractices}
          onPracticeClick={onPracticeClick}
          canViewFormedJuniors={canViewFormedJuniors(session.user)}
        />
      </Flex>
      <CenteredModal
        title={t("components.practiceModal.createPractice")}
        isOpen={isCreatePracticeModalOpen}
        setIsOpen={setIsCreatePracticeModalOpen}
        size={"lg"}
      >
        <PracticeForm
          onSubmit={onCreatePractice}
          onCancel={() => setIsCreatePracticeModalOpen(false)}
        />
      </CenteredModal>

      {currentPractice && (
        <CenteredModal
          title={t("common.practice") + " #" + currentPractice.id}
          isOpen={isUpdatePracticeModalOpen}
          setIsOpen={setIsUpdatePracticeModalOpen}
          size={"lg"}
        >
          <PracticeSummary
            practice={currentPractice}
            onSubmit={onUpdateCurrentPractice}
            onCancel={() => setIsUpdatePracticeModalOpen(false)}
          />
        </CenteredModal>
      )}
    </Flex>
  );
});
