type Props = {
  width: string;
  height?: string;
};

export const Line: React.FC<Props> = ({ width, height }: Props) => {
  return (
    <div
      style={{
        width: width,
        height: height || "1px",
        backgroundColor: "#CED4DA",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
      }}
    />
  );
};
