import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "rebass";
import { Practice } from "../../types";
import { toCurrency, toFormattedDate } from "../../utils/conversions";
import { formatName } from "../../utils/methods";
import { getMonthNameAndYear } from "../../utils/others";
import { Badge } from "../UI/Badge1";
import { Table, Tbody, Td, Th, Thead, Tr } from "./Table";

type Props = {
  practices: Practice[];
  onPracticeClick?: (practice: Practice) => void;
  canViewFormedJuniors?: boolean;
  variant?: "default" | "inPaymentThisMonth";
};
export const PracticesTable: React.FC<Props> = ({
  practices,
  onPracticeClick,
  canViewFormedJuniors,
  variant = "default",
}: Props) => {
  const { t } = useTranslation();

  const orderedPractices: Practice[] = useMemo(
    () => practices.sort((a, b) => a.id - b.id),
    [practices]
  );

  const rows: any[] = useMemo(
    () =>
      orderedPractices
        .filter((practice) => practice.user)
        .map((practice, index) => (
          <Tr
            onClick={(e) => {
              if (onPracticeClick) {
                onPracticeClick(practice);
              }
            }}
            style={{ cursor: "pointer" }}
            key={index}
          >
            <Td>{practice.id}</Td>
            {canViewFormedJuniors && (
              <Td>{formatName(practice.user?.name, practice.user?.surname)}</Td>
            )}
            <Td>{practice.customer}</Td>
            <Td>{toFormattedDate(practice.date || new Date())}</Td>
            <Td>{toCurrency(practice.totalAmount)}</Td>
            <Td>
              {practice.state === "validated" ? (
                <>
                  <Badge color="success" mr={"0.5rem"}>
                    {t("components.practicesTable.validated")}
                  </Badge>
                  {practice.paymentMonth &&
                    t("components.practicesTable.paymentIn") +
                      getMonthNameAndYear(practice.paymentMonth)}
                </>
              ) : practice.state === "toValidate" ? (
                <Badge color="warning">
                  {t("components.practicesTable.toValidate")}
                </Badge>
              ) : (
                <Badge color="danger">
                  {t("components.practicesTable.rejected")}
                </Badge>
              )}
            </Td>
          </Tr>
        )),

    [orderedPractices, t, onPracticeClick, canViewFormedJuniors]
  );

  return (
    <Flex width={"100%"} flexDirection={"column"} fontSize={"13px"}>
      <Text
        fontSize={"11px"}
        fontWeight={500}
        style={{ textTransform: "uppercase" }}
      >
        {variant === "default"
          ? t("common.practices")
          : t("common.practicesInPayment")}
      </Text>
      <Table>
        <Thead>
          <Tr>
            <Th scope="col">{t("components.practicesTable.id")}</Th>
            {canViewFormedJuniors && (
              <Th scope="col">{t("components.practicesTable.user")}</Th>
            )}
            <Th scope="col">{t("components.practicesTable.customer")}</Th>
            <Th scope="col">{t("components.practicesTable.date")}</Th>
            <Th scope="col">{t("components.practicesTable.totalAmount")}</Th>
            <Th scope="col">{t("components.practicesTable.state")}</Th>
          </Tr>
        </Thead>
        <Tbody>{rows.length > 0 ? rows : <></>}</Tbody>
      </Table>
    </Flex>
  );
};
