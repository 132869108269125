import ApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

type Props = {
  percentage: number;
  width?: string | number;
  height?: string | number;
  //objectives?: Objective[]
};

export const RadialPercentage: React.FC<Props> = ({
  percentage,
  width,
  height,
}: Props) => {
  const { t } = useTranslation();
  let color = "#7FE3E2";

  return (
    <ApexRadialChart
      percentage={percentage}
      width={width || "100%"}
      height={height || 200}
      color={color}
      text={t("components.careerPlan.reached").toUpperCase()}
    />
  );
};

interface IProps {
  percentage: number;
  width: string | number;
  height: string | number;
  text: string;
  color?: string;
}

export const ApexRadialChart: React.FC<IProps> = ({
  width,
  height,
  percentage,
  text,
  color,
}) => {
  const data = {
    series: [percentage],
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      colors: [color],

      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "65%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 25,
              show: true,
              color: "#8392a5",
              fontSize: "14px",
            },
            value: {
              offsetY: -10,
              color: "#021736",
              fontSize: "40px",
              show: true,
            },
          },
        },
      },
      labels: [text],
      responsive: [
        {
          breakpoint: "576px",
          options: {
            chart: {
              offsetX: -78,
            },
          },
        },
      ],
    },
  };
  return (
    <ApexChart
      type="radialBar"
      series={data.series}
      //@ts-ignore
      options={data.options}
      width={width}
      height={height}
    />
  );
};
