import styled from "styled-components";
import { Card } from "../../UI/Card";

export const CredentialsContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 6px;
`;

export const CredentialsText = styled.p`
  margin: 0;
  margin-left: 0.5rem;
  font-size: 18px;
`;
