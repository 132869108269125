import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledBottomText,
  StyledLabelWrap,
} from "./style";
import { hasKey } from "../../../utils/methods";
import { FormGroup, Input, Label } from "../../Common/Forms";
import { Anchor } from "../../Common/Anchor";
import { Button } from "../../Common/Button";
import { useTranslation } from "react-i18next";
import { Alert } from "../../Common/Alert";
import { AlertMessage } from "../../../types";

interface IFormValues {
  username: string;
  password: string;
}

type Props = {
  message?: AlertMessage;
  isSubmitting: boolean;
  onSubmit: (password: string) => void;
};

const ChangePasswordForm: FC<Props> = ({ message, isSubmitting, onSubmit }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (data: IFormValues) => {
      onSubmit(data.password);
    },
    [onSubmit]
  );

  return (
    <StyledWrap>
      <StyledTitle>{t("components.changePasswordForm.title")}</StyledTitle>
      <StyledDesc>{t("components.changePasswordForm.description")}</StyledDesc>
      {message && (
        <Alert
          hasIcon={false}
          hasLink={false}
          isDismissible={false}
          solid={false}
          variant="contained"
          color={message.type}
        >
          {message.message}
        </Alert>
      )}
      <form action="#" onSubmit={handleSubmit(handleOnSubmit)} noValidate>
        <FormGroup mb="20px">
          <StyledLabelWrap>
            <Label display="block" mb="5px" htmlFor="password">
              {t("common.password")}
            </Label>
          </StyledLabelWrap>
          <Input
            id="password"
            type="password"
            placeholder="Enter your password"
            feedbackText={errors?.password?.message}
            state={hasKey(errors, "password") ? "error" : "success"}
            showState={!!hasKey(errors, "password")}
            {...register("password", {
              required: t(
                "messages.formValidations.common.passwordRequired"
              ).toString(),
              minLength: {
                value: 6,
                message: t(
                  "messages.formValidations.common.passwordMinLength"
                ).toString(),
              },
            })}
          />
        </FormGroup>

        <FormGroup mb="20px">
          <StyledLabelWrap>
            <Label display="block" mb="5px" htmlFor="confirmPassword">
              {t("components.changePasswordForm.confirmPassword")}
            </Label>
          </StyledLabelWrap>
          <Input
            id="confirmPassword"
            type="password"
            placeholder="Enter your password"
            feedbackText={errors?.confirmPassword?.message}
            state={hasKey(errors, "confirmPassword") ? "error" : "success"}
            showState={!!hasKey(errors, "confirmPassword")}
            {...register("confirmPassword", {
              required: t(
                "messages.formValidations.common.passwordRequired"
              ).toString(),
              minLength: {
                value: 6,
                message: t(
                  "messages.formValidations.common.passwordMinLength"
                ).toString(),
              },
              validate: (value) =>
                value === watch("password") ||
                t(
                  "messages.formValidations.common.passwordsNotMatch"
                ).toString(),
            })}
          />
        </FormGroup>

        <Button type="submit" color="brand2" fullwidth disabled={isSubmitting}>
          {t("common.confirm")}
        </Button>

        <StyledBottomText>
          <Anchor path="/login" color="brand2" fontSize="15px">
            {t("common.backToLogin")}
          </Anchor>
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default ChangePasswordForm;
