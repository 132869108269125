import styled from "styled-components";
import { themeGet } from "../../../styled";
import { Card } from "../../UI/Card";

export const Container = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4rem;
`;

export const ExternalCard = styled(Card)`
  width: 20vw;
  min-width: 10rem;
  max-width: 15rem;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
`;

export const RoleText = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: #1b2e4bb2;
  padding-bottom: 2rem;
`;

export const CareerPlanText = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: ${themeGet("colors.text1")};
  text-transform: uppercase;
`;

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.7rem;
`;

export const CheckText = styled.p`
  padding-left: 0.5rem;
  font-size: 16px;
  font-weight: 400;
  color: ${themeGet("colors.text1")};
`;
