import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { ErrorText } from "./Forms/ErrorText";
import * as Yup from "yup";
import { FormGroup, Label } from "./Forms";
import { Textarea } from "./Forms/Textarea";

type Props = {
  initialValues?: { text: string };
  onSubmit?: (text: string) => any;
  onCancel?: () => any;
  isSubmitting?: boolean;
};

export const ObjectiveForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    text: Yup.string()
      .required(t("messages.errors.objectiveForm.textRequired"))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          text: null,
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (onSubmit && values.text) onSubmit(values.text);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Text */}
        {formik.touched.text && formik.errors.text && (
          <ErrorText>{formik.errors.text}</ErrorText>
        )}
        <FormGroup mb={"20px"}>
          <Label display={"block"} mb={"5px"} htmlFor={"text"}>
            {t("components.objectiveForm.text")}
          </Label>
          <Textarea
            id={"text"}
            name={"text"}
            value={formik.values.text || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.objectiveForm.text")}
          />
        </FormGroup>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant={"outlined"}
            fontSize={"16px"}
            disabled={isSubmitting}
            mr={"1rem"}
            onClick={onCancel}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type={"submit"}
            color={"brand2"}
            fontSize={"16px"}
            disabled={isSubmitting}
          >
            {t("common.confirm")}
          </Button>
        </div>
      </div>
    </form>
  );
};
