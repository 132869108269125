import {
  Commission,
  ContactItemType,
  Objective,
  User,
} from "../../../../types";
import { StyledBrand } from "./style";
import { Text } from "rebass";
import { useTranslation } from "react-i18next";
import { CareerPlan } from "../../../Common/CareerPlan";
import {
  canViewFormedJuniors,
  getFormedJuniorsProgressCardItems,
  canViewFormedSeniors,
  getFormedSeniorsProgressCardItems,
} from "../../../../utils/roles";
import { ProgressCard } from "../../../Common/ProgressCard";
import { useState } from "react";
import { UsersListModal } from "../../../Common/UsersListModal";

type Props = {
  user?: User;
  objectives?: Objective[];
  commissions?: Commission | null;
  onEditCheck?: (id: number, completed: boolean) => any;
  onUpdateUserRole?: (updateType: "upgrade" | "downgrade") => any;
  canUpgradeCurrentUserRole?: boolean;
  canDowngradeCurrentUserRole?: boolean;
  onFormedUserClick?: (item: ContactItemType) => void;
};
export const PersonalDetails: React.FC<Props> = ({
  user,
  commissions,
  objectives,
  onEditCheck,
  onUpdateUserRole,
  canUpgradeCurrentUserRole,
  canDowngradeCurrentUserRole,
  onFormedUserClick,
}: Props) => {
  const { t } = useTranslation();
  const [isTrainedJuniorsModalOpen, setIsTrainedJuniorsModalOpen] =
    useState(false);
  const [isTrainedSeniorsModalOpen, setIsTrainedSeniorsModalOpen] =
    useState(false);

  if (!user) return <></>;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div>
          <StyledBrand color={user.color} />
        </div>
        <Data user={user} />
      </div>
      <CareerPlan
        variant={"essential"}
        role={user.role}
        objectives={objectives}
        onEditCheck={onEditCheck}
        onUpdateUserRole={onUpdateUserRole}
        canUpgradeCurrentUserRole={canUpgradeCurrentUserRole}
        canDowngradeCurrentUserRole={canDowngradeCurrentUserRole}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingBottom: "10rem",
        }}
      >
        {canViewFormedJuniors(user) && (
          <ProgressCard
            items={getFormedJuniorsProgressCardItems(user, commissions || null)}
            onClick={() => setIsTrainedJuniorsModalOpen(true)}
          />
        )}
        {canViewFormedSeniors(user) && (
          <ProgressCard
            items={getFormedSeniorsProgressCardItems(user, commissions || null)}
            onClick={() => setIsTrainedSeniorsModalOpen(true)}
          />
        )}
      </div>
      <UsersListModal
        title={t("roles.formedJuniors")}
        users={user.trainedJuniors || []}
        isOpen={isTrainedJuniorsModalOpen}
        setIsOpen={setIsTrainedJuniorsModalOpen}
        handleUserClick={(item) => {
          setIsTrainedJuniorsModalOpen(false);
          onFormedUserClick && onFormedUserClick(item);
        }}
      />
      <UsersListModal
        title={t("roles.formedSeniors")}
        users={user.trainedSeniors || []}
        isOpen={isTrainedSeniorsModalOpen}
        setIsOpen={setIsTrainedSeniorsModalOpen}
        handleUserClick={(item) => {
          setIsTrainedSeniorsModalOpen(false);
          onFormedUserClick && onFormedUserClick(item);
        }}
      />
    </div>
  );
};

export const Data: React.FC<Props> = ({ user }: Props) => {
  const { t } = useTranslation();

  if (!user) return <></>;
  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginLeft: "2rem" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10%",
        }}
      >
        <Field name={t("common.name")} value={user.name} />
        <Field name={t("common.surname")} value={user.surname} />
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Field name={t("common.phone")} value={user.phoneNumber} />
      </div>
    </div>
  );
};

export const Field: React.FC<{ name: string; value: string }> = ({
  name,
  value,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        fontSize={"14px"}
        fontWeight={500}
        style={{ textTransform: "uppercase", marginRight: "10vw" }}
      >
        {name}
      </Text>
      <Text fontSize={"18px"} fontWeight={400}>
        {value}
      </Text>
    </div>
  );
};
