import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { ErrorText } from "./Forms/ErrorText";
import * as Yup from "yup";
import { FormGroup, Input, Label } from "./Forms";
import { SalesThreshold } from "../../types";

type Props = {
  initialValues?: SalesThreshold;
  onSubmit?: (quantity: number, bonus: number) => any;
  onCancel?: () => any;
  isSubmitting?: boolean;
};

export const SalesThresholdForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().required(
      t("messages.errors.salesThresholdForm.quantityRequired")
    ),
    bonus: Yup.number().required(
      t("messages.errors.salesThresholdForm.bonusRequired")
    ),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          quantity: 1,
          bonus: 0,
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (onSubmit && values.bonus && values.quantity)
        onSubmit(values.quantity, values.bonus);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Quantity */}
        {formik.touched.quantity && formik.errors.quantity && (
          <ErrorText>{formik.errors.quantity}</ErrorText>
        )}
        <FormGroup mb={"20px"}>
          <Label display={"block"} mb={"5px"} htmlFor={"quantity"}>
            {t("components.salesThresholdForm.quantity")}
          </Label>
          <Input
            id={"quantity"}
            name={"quantity"}
            type={"number"}
            value={formik.values.quantity || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.salesThresholdForm.quantity")}
          />
        </FormGroup>
        {/* Bonus */}
        {formik.touched && formik.errors.bonus && (
          <ErrorText>{formik.errors.bonus}</ErrorText>
        )}
        <FormGroup mb={"20px"}>
          <Label display={"block"} mb={"5px"} htmlFor={"bonus"}>
            {t("components.salesThresholdForm.bonus")}
          </Label>
          <Input
            id={"bonus"}
            name={"bonus"}
            type={"number"}
            value={formik.values.bonus || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.salesThresholdForm.bonus")}
          />
        </FormGroup>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant={"outlined"}
            fontSize={"16px"}
            disabled={isSubmitting}
            mr={"1rem"}
            onClick={onCancel}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type={"submit"}
            color={"brand2"}
            fontSize={"16px"}
            disabled={isSubmitting}
          >
            {t("common.confirm")}
          </Button>
        </div>
      </div>
    </form>
  );
};
