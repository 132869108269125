import dayjs from "dayjs";
import { Period } from "../../types";
import { Text } from "rebass";

export const StyledPeriod: React.FC<{ period: Period }> = ({ period }) => (
  <Text marginLeft={"0.5rem"} fontSize={"14px"}>
    {dayjs(period.fromDate).format("DD MMM YY")} -{" "}
    {dayjs(period.toDate).format("DD MMM YY")}
  </Text>
);
