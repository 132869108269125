import { observer } from "mobx-react";

import { useStores } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { ProgressCard } from "../../components/Common/ProgressCard";
import { ExternalContainer, ProgressContainer } from "./style";
import { useCallback, useEffect, useState } from "react";
import { ProgressCardItemType, User } from "../../types";
import dayjs from "dayjs";
import { Flex } from "rebass";
import { CalendarNavButtons } from "../../components/Common/CalendarNavButtons";
import { StyledPeriod } from "../../components/Common/StyledPeriod";
import { toCurrency } from "../../utils/conversions";
import { UsersWithCommissionsTable } from "../../components/Common/UsersWithCommissionsTable";
import { useNavigate } from "react-router-dom";

export const Payments: React.FC = observer(() => {
  const { session } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [usersProgressItems, setUsersProgressItems] = useState<
    ProgressCardItemType[]
  >([]);
  const [paymentProgressItems, setPaymentProgressItems] = useState<
    ProgressCardItemType[]
  >([]);
  const [period, setPeriod] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: dayjs().startOf("month").toDate(),
    toDate: dayjs().endOf("month").toDate(),
  });
  const [usersWithCommissions, setUsersWithCommissions] = useState<User[]>([]);
  const init = useCallback(async () => {
    try {
      let requestDate = new Date(
        period.fromDate.getFullYear(),
        period.fromDate.getMonth()
      );
      requestDate.setMonth(requestDate.getMonth() + 1);

      setUsersProgressItems([
        {
          name: t("screens.payments.usersNumber"),
          value: (await session.countUsers()).toString(),
        },
      ]);
      setPaymentProgressItems([
        {
          name: t("screens.payments.totalPayments"),
          value: toCurrency(
            await session.getTotalPayments(
              requestDate.getMonth(),
              requestDate.getFullYear()
            )
          ),
        },
      ]);
      setUsersWithCommissions(
        await session.getUsersWithCommissions(
          requestDate.getFullYear(),
          requestDate.getMonth()
        )
      );
    } catch (e) {
      console.log(e);
    }
  }, [period, session, t]);
  useEffect(() => {
    init();
  }, [session, t, period.fromDate, init]);

  const handleUserClick = useCallback(
    (user: User) => {
      navigate(`/admin/users-management/${user.id}`);
    },
    [navigate]
  );

  const handleReversalChange = useCallback(
    async (amount: number, userId: User["id"], id?: number) => {
      try {
        await session.createOrUpdateReversal(
          amount,
          new Date(period.fromDate),
          userId,
          id
        );
        init();
      } catch (e) {
        console.log(e);
      }
    },
    [init, session, period]
  );

  if (!session.user) return <></>;

  return (
    <ExternalContainer>
      <Flex alignItems={"center"}>
        <CalendarNavButtons onChange={setPeriod} />
        <StyledPeriod period={period} />
      </Flex>
      <ProgressContainer>
        <ProgressCard items={usersProgressItems} />
        <ProgressCard items={paymentProgressItems} />
      </ProgressContainer>
      <UsersWithCommissionsTable
        users={usersWithCommissions}
        onUserClick={handleUserClick}
        onChangeReversal={handleReversalChange}
      />
    </ExternalContainer>
  );
});
