import { FC } from "react";
import { ContactItemType } from "../../../../types";
import { Brand } from "../../../Common/Brand";
import { MediaBody } from "../../Media";
import { StyledMedia, StyledName, StyledSpan, StyledNav } from "./style";

interface IProps {
  id: string;
  item: ContactItemType;
  activeId?: number;
  onClick?: (item: ContactItemType) => void;
}
const ContactItem: FC<IProps> = ({ id, item, activeId, onClick }) => {
  return (
    <StyledMedia
      id={id}
      $active={activeId && activeId === Number(item.id)}
      onClick={() => onClick && onClick(item)}
      style={{ alignItems: "center" }}
    >
      <Brand color={item.color} style={{ width: "2.3rem", height: "2.3rem" }} />
      <MediaBody ml="10px">
        <StyledName>{item.primaryText}</StyledName>
        <StyledSpan>{item.secondaryText}</StyledSpan>
      </MediaBody>
      <StyledNav>
        {/* <StyledNavBtn>{"Star"}</StyledNavBtn>
        <StyledNavBtn>{"Edit"}</StyledNavBtn> */}
      </StyledNav>
    </StyledMedia>
  );
};

export default ContactItem;
