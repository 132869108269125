import { t } from "i18next";
import { useCallback, useState } from "react";
import { Flex } from "rebass";
import {
  Practice,
  PracticeState,
  ProgressCardItemType,
  User,
} from "../../types";
import { getPracticesInPayment } from "../../utils/others";
import { canViewFormedJuniors } from "../../utils/roles";
import { Button } from "./Button";
import { CalendarNavButtons } from "./CalendarNavButtons";
import { CenteredModal } from "./CenteredModal";
import { PracticeForm } from "./PracticeForm";
import { PracticesTable } from "./PracticesTable";
import { PracticeSummary } from "./PracticeSummary";
import { ProgressCard } from "./ProgressCard";
import { StyledPeriod } from "./StyledPeriod";

type Props = {
  user?: User;
  practices?: Practice[];
  canCreatePractices?: boolean;
  onCreatePractice?: (
    customer: string,
    date: Date,
    totalAmount: number,
    description?: string
  ) => void;
  progressItems: ProgressCardItemType[][];
  currentPractice?: Practice;
  onUpdateCurrentPractice?: (state: PracticeState) => Promise<void>;
  onPracticeClick?: (practice: Practice) => void;
  period: { fromDate: Date; toDate: Date };
  setPeriod: (period: { fromDate: Date; toDate: Date }) => void;
  preventViewingFormedJuniors?: boolean;
};

export const UserPracticesSummary: React.FC<Props> = ({
  user,
  practices,
  canCreatePractices,
  onCreatePractice,
  progressItems,
  currentPractice,
  onUpdateCurrentPractice,
  onPracticeClick,
  period,
  setPeriod,
  preventViewingFormedJuniors,
}: Props) => {
  const [isCreatePracticeModalOpen, setIsCreatePracticeModalOpen] =
    useState(false);
  const [isPracticeSummaryModalOpen, setIsPracticeSummaryModalOpen] =
    useState(false);

  const practiceClick = useCallback(
    (practice: Practice) => {
      onPracticeClick && onPracticeClick(practice);
      setIsPracticeSummaryModalOpen(true);
    },
    [onPracticeClick]
  );

  if (!user) return <></>;

  return (
    <Flex flexDirection={"column"}>
      {/* First row */}
      <Flex flexDirection={"row"} alignItems={"center"} width={"100%"}>
        <Flex alignItems={"center"}>
          <CalendarNavButtons onChange={setPeriod} />
          <StyledPeriod period={period} />
        </Flex>
        {canCreatePractices && (
          <Flex flexGrow={1} justifyContent={"flex-end"}>
            <Button
              fontSize={"16px"}
              onClick={() => {
                setIsCreatePracticeModalOpen(true);
              }}
            >
              {t("common.addPractice")}
            </Button>
          </Flex>
        )}
      </Flex>
      {/* ProgressItems */}
      <Flex
        flexDirection={"row"}
        mt={"2rem"}
        alignItems={"center"}
        justifyContent={"space-between"}
        overflow={"auto"}
        height={"11.5rem"}
      >
        {progressItems.map((items, index) => (
          <ProgressCard items={items} key={index} />
        ))}
      </Flex>
      {/* PracticesTable */}
      <Flex marginTop={"2rem"} width={"100%"}>
        <PracticesTable
          practices={practices || user.practices}
          onPracticeClick={practiceClick}
          canViewFormedJuniors={
            preventViewingFormedJuniors ? false : canViewFormedJuniors(user)
          }
        />
      </Flex>

      {/* PracticesTable (practices in payment this month) */}
      <Flex marginTop={"2rem"} width={"100%"}>
        <PracticesTable
          practices={getPracticesInPayment(period, practices || user.practices)}
          onPracticeClick={practiceClick}
          canViewFormedJuniors={
            preventViewingFormedJuniors ? false : canViewFormedJuniors(user)
          }
          variant={"inPaymentThisMonth"}
        />
      </Flex>
      {canCreatePractices && onCreatePractice && (
        <CenteredModal
          title={t("components.practiceModal.createPractice")}
          isOpen={isCreatePracticeModalOpen}
          setIsOpen={setIsCreatePracticeModalOpen}
          size={"lg"}
        >
          <PracticeForm
            onSubmit={(
              customer: string,
              date: Date,
              totalAmount: number,
              description?: string | undefined
            ) => {
              onCreatePractice(customer, date, totalAmount, description);
              setIsCreatePracticeModalOpen(false);
            }}
            onCancel={() => setIsCreatePracticeModalOpen(false)}
          />
        </CenteredModal>
      )}

      {currentPractice && (
        <CenteredModal
          title={t("common.practice") + " #" + currentPractice.id}
          isOpen={isPracticeSummaryModalOpen}
          setIsOpen={setIsPracticeSummaryModalOpen}
          size={"lg"}
        >
          <PracticeSummary
            practice={currentPractice}
            onCancel={() => setIsPracticeSummaryModalOpen(false)}
          />
        </CenteredModal>
      )}
    </Flex>
  );
};
