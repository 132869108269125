import { makeAutoObservable } from "mobx";
import api from "../services/api";
import { Practice, Stores, User } from "./../types";
import { configure } from "mobx";

configure({
  enforceActions: "never",
});

export class PracticesStore {
  stores: Stores;
  isFetching = false;
  currentPractices: Practice[] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  getUserPractices = async (
    user?: User,
    startDate?: Date,
    endDate?: Date
  ): Promise<Practice[]> => {
    if (!this.stores.session.user) return [];
    this.isFetching = true;
    let toReturn: Practice[] = [];
    try {
      toReturn = await api.getUserPractices(user, startDate, endDate);
      if (toReturn && !user) {
        this.currentPractices = toReturn;
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
      return toReturn;
    }
  };

  createPractice = async (practice: Practice): Promise<Practice | null> => {
    if (!this.stores.session.user) return null;
    this.isFetching = true;
    let toReturn: Practice | null = null;
    try {
      const returnedPractice = await api.createPractice(practice);
      if (returnedPractice) {
        toReturn = returnedPractice;
        await this.getUserPractices();
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
      return toReturn;
    }
  };
  updatePractice = async (id: number, data: any): Promise<Practice | null> => {
    if (!this.stores.session.user) return null;
    this.isFetching = true;
    let toReturn: Practice | null = null;
    try {
      const returnedPractice = await api.updatePractice(id, data);
      if (returnedPractice) {
        toReturn = returnedPractice;
        await this.getUserPractices();
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
      return toReturn;
    }
  };
}
