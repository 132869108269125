import React from "react";
import { ProgressCardItemType } from "../../../types";
import { Progress } from "../../UI/Progress";
import { NameText, StyledCard, ValueText } from "./style";

type Props = {
  items: ProgressCardItemType[];
  onClick?: () => any;
  style?: React.CSSProperties;
};

export const ProgressCard: React.FC<Props> = ({
  items,
  onClick,
  style,
}: Props) => {
  return (
    <StyledCard
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "default", ...style }}
    >
      {items.map((item, index) => (
        <ProgressCardItem item={item} key={index} />
      ))}
    </StyledCard>
  );
};

export const ProgressCardItem: React.FC<{ item: ProgressCardItemType }> = ({
  item,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
      // @ts-ignore
      tooltip={item.onHoverText}
    >
      <ValueText>{item.value}</ValueText>
      <NameText>{item.name}</NameText>
      {item.percentage && (
        <Progress now={item.percentage} bg={"#563D7C"} height={"4px"} />
      )}
    </div>
  );
};
