import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineMenu } from "react-icons/hi";
import { Navbar } from "../NavBar";
import {
  StyledNavbarWrap,
  StyledNavbarMenu,
  StyledNavbarHeader,
  StyledNavbarBody,
  MenuText,
  XText,
} from "./styled";

type Props = {
  menu: any;
};

export const Drawer: React.FC<Props> = ({ menu }: Props) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const menuHandler = useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, []);

  return (
    <>
      <HiOutlineMenu cursor={"pointer"} size={"1.5rem"} onClick={menuHandler} />
      <StyledNavbarWrap $isOpen={menuOpen} onClick={menuHandler}>
        <StyledNavbarMenu
          $isOpen={menuOpen}
          onClick={(e) => e.stopPropagation()}
        >
          <StyledNavbarHeader>
            <MenuText>{t("components.menu.menu")}</MenuText>
            <XText onClick={menuHandler}>{"x"}</XText>
          </StyledNavbarHeader>
          <StyledNavbarBody>
            <Navbar menus={menu} menuHandler={menuHandler} />
          </StyledNavbarBody>
        </StyledNavbarMenu>
      </StyledNavbarWrap>
    </>
  );
};
