import { useState } from "react";
import { ContactItemType, User } from "../../types";
import { userToContactItemType } from "../../utils/conversions";
import { filterContactItemsByText } from "../../utils/others";
import AllContacts from "../UI/Contacts/AllContacts";
import SidebarHeader from "../UI/Contacts/SidebarHeader";
import { CenteredModal } from "./CenteredModal";

type Props = {
  title: string;
  users: User[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => any;
  handleUserClick?: (item: ContactItemType) => void;
};

export const UsersListModal = ({
  title,
  users,
  isOpen,
  setIsOpen,
  handleUserClick,
}: Props) => {
  const [searchedText, setSearchedText] = useState("");

  return (
    <CenteredModal title={title} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div style={{ marginBottom: "0.5rem" }}>
        <SidebarHeader
          searchedText={searchedText}
          setSearchedText={setSearchedText}
        />
      </div>
      <AllContacts
        items={filterContactItemsByText(
          searchedText,
          users.map((user) => userToContactItemType(user))
        )}
        clickHandler={handleUserClick}
      />
    </CenteredModal>
  );
};
