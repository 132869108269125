import { useTranslation } from "react-i18next";
import { Text } from "rebass";
import { Button } from "./Button";
import { CenteredModal } from "./CenteredModal";

type Props = {
  title: string;
  text: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => any;
  onDelete: () => any;
};

export const DeleteModal = ({
  title,
  text,
  isOpen,
  setIsOpen,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  return (
    <CenteredModal title={title} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Text fontSize={"18px"} mb={"1.5rem"}>
        {text}
      </Text>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant={"outlined"}
          fontSize={"16px"}
          mr={"1rem"}
          onClick={() => setIsOpen(false)}
        >
          {t("common.cancel")}
        </Button>
        <Button
          color={"brand2"}
          fontSize={"16px"}
          onClick={() => {
            onDelete();
            setIsOpen(false);
          }}
        >
          {t("common.confirm")}
        </Button>
      </div>
    </CenteredModal>
  );
};
