import {
  HiAdjustments,
  HiOutlineArchive,
  HiOutlineUserGroup,
  HiOutlineCurrencyEuro,
} from "react-icons/hi";

export const adminMenu = [
  {
    id: 0,
    label: "components.menu.practices",
    url: "/admin/practices",
    Icon: HiOutlineArchive,
  },
  {
    id: 1,
    label: "components.menu.usersManagement",
    url: "/admin/users-management",
    Icon: HiOutlineUserGroup,
  },
  {
    id: 2,
    label: "components.menu.rolesManagement",
    url: "/admin/roles-management",
    Icon: HiAdjustments,
  },
  {
    id: 3,
    label: "components.menu.payments",
    url: "/admin/payments",
    Icon: HiOutlineCurrencyEuro,
  },
];

export const juniorMenu = [
  {
    id: 0,
    label: "components.menu.practices",
    url: "/user/practices",
    Icon: HiOutlineArchive,
  },
];
