import { useCallback, useEffect, useState } from "react";
import Content from "../../components/Common/Content";
import ChangePassword from "../../components/Auth/ChangePassword";
import { useLocation, useNavigate } from "react-router";
import { useStores } from "../../hooks/useStores";
import { AlertMessage } from "../../types";
import { useTranslation } from "react-i18next";

export const ChangePasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [code, setCode] = useState<string | null>(null);
  const [forgotPasswordMessage, setForgotPasswordMessage] =
    useState<AlertMessage>();
  const navigate = useNavigate();

  const { session } = useStores();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    setCode(searchParams.get("code"));
    if (!searchParams.get("code")) {
      navigate("/login");
    }
  }, [location, navigate]);

  const handleSubmit = useCallback(
    async (password: string) => {
      try {
        if (code) {
          await session.verifyPasswordResetCode(password, password, code);
          navigate("/login", {
            state: {
              message: {
                type: "success",
                message: t(
                  "messages.successes.changePasswordForm.updatedPassword"
                ),
              },
            },
          });
        }
      } catch (e) {
        setForgotPasswordMessage({
          type: "danger",
          message: t("messages.errors.changePasswordForm.generic"),
        });
        console.log(e);
      }
    },
    [session, navigate, code, t]
  );

  return (
    <Content fullHeight>
      <ChangePassword
        onSubmit={handleSubmit}
        isSubmitting={session.isSubmitting}
        message={forgotPasswordMessage}
      />
    </Content>
  );
};
