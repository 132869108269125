import { observer } from "mobx-react";
import { Outlet } from "react-router";
import { Topbar } from "../Topbar";
import { LayoutContentContainer } from "./styled";

export const AppLayout: React.FC = observer(({ children }) => {
  return (
    <>
      <Topbar />
      <LayoutContentContainer>
        <Outlet />
      </LayoutContentContainer>
    </>
  );
});
