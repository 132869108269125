import styled from "styled-components";

export const RoleSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 2rem;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SectionTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
`;
