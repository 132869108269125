import styled from "styled-components";

export const ExternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 3rem;
  padding: 2%;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 15rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
