import styled from "styled-components";

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextInputText = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #1b2e4bb2;
`;

export const TextInputInternalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
