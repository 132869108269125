import { useCallback, useState } from "react";
import {
  HiOutlineCheck,
  HiOutlineTrash,
  HiPencil,
  HiReply,
} from "react-icons/hi";
import { toCurrency } from "../../../utils/conversions";
import { Input } from "../Forms";
import { Textarea } from "../Forms/Textarea";
import {
  TextInputContainer,
  TextInputInternalContainer,
  TextInputText,
} from "./style";

type Props = {
  value: string | number;
  onChange?: (value: string | number) => any;
  onDelete?: () => any;
  inputType?: "text" | "number" | "currency" | "textarea";
};

export const TextInput: React.FC<Props> = ({
  value,
  onChange,
  onDelete,
  inputType = "textarea",
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      onChange && onChange(currentValue);
      setIsEditing(false);
    },
    [onChange, currentValue]
  );

  return (
    <TextInputContainer>
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <TextInputInternalContainer>
            {inputType !== "textarea" ? (
              <Input
                name={"text"}
                id={"text"}
                type={inputType === "currency" ? "number" : inputType}
                width={"10rem"}
                value={currentValue}
                onChange={(e) => {
                  setCurrentValue(e.target.value);
                }}
                mr={"0.5rem"}
                ml={"0.3rem"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            ) : (
              <Textarea
                name={"text"}
                id={"text"}
                width={"20rem"}
                value={currentValue}
                onChange={(e) => {
                  setCurrentValue(e.target.value);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                mr={"0.5rem"}
                ml={"0.3rem"}
              />
            )}
            <div
              style={{ display: "flex", flexDirection: "row", width: "5rem" }}
            >
              <HiOutlineCheck
                type={"submit"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit(e);
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  marginRight: "0.2rem",
                }}
              />
              <HiReply
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsEditing(false);
                }}
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
            </div>
          </TextInputInternalContainer>
        </form>
      ) : (
        <TextInputInternalContainer>
          <TextInputText>
            {inputType === "currency" ? toCurrency(+value) : value}
          </TextInputText>
          <div style={{ display: "flex", flexDirection: "row", width: "5rem" }}>
            <HiPencil
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsEditing(true);
              }}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                marginRight: "0.2rem",
              }}
            />
            {onDelete && (
              <HiOutlineTrash
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete && onDelete();
                  setIsEditing(false);
                }}
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
            )}
          </div>
        </TextInputInternalContainer>
      )}
    </TextInputContainer>
  );
};
