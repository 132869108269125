import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  AlertMessage,
  Role,
  UpdateOrCreateUserParams,
  User,
} from "../../types";
import { Alert } from "../Common/Alert";
import { FormGroup, Input, Label } from "../Common/Forms";
import { Button } from "../Common/Button";
import { CustomSelect } from "../Common/Forms/CustomSelect";
import * as Yup from "yup";
import { phoneRegEx } from "../../utils/others";
import { ErrorText } from "../Common/Forms/ErrorText";
import { formatName } from "../../utils/methods";

type Props = {
  message?: AlertMessage;
  isSubmitting: boolean;
  user?: User;
  users: User[];
  roles: Role[];
  onSubmit: (user: UpdateOrCreateUserParams) => any;
  onCancel?: (id: string) => any;
};

const UserForm: FC<Props> = ({
  message,
  isSubmitting,
  user,
  onSubmit,
  onCancel,
  roles,
  users,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t("messages.errors.credentialsForm.nameRequired")
    ),
    surname: Yup.string().required(
      t("messages.errors.credentialsForm.surnameRequired")
    ),
    email: Yup.string()
      .email()
      .required(t("messages.errors.credentialsForm.emailRequired")),
    phoneNumber: Yup.string()
      .matches(
        phoneRegEx,
        t("messages.errors.credentialsForm.phoneNumberNotValid")
      )
      .required(t("messages.errors.credentialsForm.phoneNumberRequired")),
    password: Yup.string()
      .min(6, t("messages.formValidations.common.passwordMinLength"))
      .nullable(),
    confirmPassword: Yup.string()
      .min(6, t("messages.formValidations.common.passwordMinLength"))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      id: user?.id || "",
      name: user?.name || "",
      surname: user?.surname || "",
      phoneNumber: user?.phoneNumber || "",
      email: user?.email || "",
      roleId: user?.role?.id || "1",
      password: "",
      confirmPassword: "",
      seniorId: user?.senior?.id,
      supervisorId: user?.supervisor?.id,
      areaManagerId: user?.manager?.id,
    },
    validationSchema,
    onSubmit,
  });

  const roleOptions = useMemo(() => {
    return roles.map((role) => ({
      value: role.id,
      label: t("roles." + role.name),
    }));
  }, [roles, t]);

  const selectedRoleOption = useMemo(() => {
    const role = roles.find((p) => p.id === formik.values.roleId);

    return {
      value: role?.id,
      label: t("roles." + role?.name),
    };
  }, [formik.values.roleId, roles, t]);

  const usersOptions = useMemo(() => {
    let options = [
      {
        value: 0,
        label: t("common.nobody"),
      },
    ];
    Array.prototype.push.apply(
      options,
      users
        .map((user) => ({
          value: user.id,
          label: formatName(user.name, user.surname),
        }))
        .filter((option) => !user || option.value !== user.id)
    );
    return options;
  }, [users, user, t]);

  const selectedSupervisorOption = useMemo(() => {
    const user = users.find((p) => p.id === formik.values.supervisorId);

    return {
      value: user ? user.id : 0,
      label: user ? formatName(user.name, user.surname) : t("common.nobody"),
    };
  }, [formik.values.supervisorId, users, t]);

  const selectedAreaManagerOption = useMemo(() => {
    const user = users.find((p) => p.id === formik.values.areaManagerId);

    return {
      value: user ? user.id : 0,
      label: user ? formatName(user.name, user.surname) : t("common.nobody"),
    };
  }, [formik.values.areaManagerId, users, t]);

  const selectedSeniorOption = useMemo(() => {
    const user = users.find((p) => p.id === formik.values.seniorId);

    return {
      value: user ? user.id : 0,
      label: user ? formatName(user.name, user.surname) : t("common.nobody"),
    };
  }, [formik.values.seniorId, users, t]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ height: "100%", width: "100%", padding: "1rem" }}
    >
      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="name">
          {t("common.name")}
        </Label>
        <Input
          type="text"
          id="name"
          name={"name"}
          placeholder={t("common.name")}
          value={formik.values.name}
          onChange={(e) => formik.setFieldValue("name", e.target.value)}
        />
        {formik.touched.name && formik.errors.name && (
          <ErrorText>{formik.errors.name}</ErrorText>
        )}
      </FormGroup>
      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="surname">
          {t("common.surname")}
        </Label>
        <Input
          type="text"
          id="surname"
          name={"surname"}
          placeholder={t("common.surname")}
          value={formik.values.surname}
          onChange={(e) => formik.setFieldValue("surname", e.target.value)}
        />
        {formik.touched.surname && formik.errors.surname && (
          <ErrorText>{formik.errors.surname}</ErrorText>
        )}
      </FormGroup>
      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="email">
          {t("common.email")}
        </Label>
        <Input
          type="email"
          id="email"
          name={"email"}
          placeholder="nome@email.com"
          value={formik.values.email}
          onChange={(e) => formik.setFieldValue("email", e.target.value)}
        />

        {formik.touched.email && formik.errors.email && (
          <ErrorText>{formik.errors.email}</ErrorText>
        )}
      </FormGroup>
      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="phoneNumber">
          {t("common.phone")}
        </Label>
        <Input
          type="text"
          id="phoneNumber"
          name={"phoneNumber"}
          value={formik.values.phoneNumber}
          onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
        />

        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <ErrorText>{formik.errors.phoneNumber}</ErrorText>
        )}
      </FormGroup>
      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="password">
          {t("common.password")}
        </Label>
        <Input
          id="password"
          type="password"
          placeholder={t("common.password")}
          name={"password"}
          value={formik.values.password}
          onChange={(e) => formik.setFieldValue("password", e.target.value)}
        />

        {formik.touched.password && formik.errors.password && (
          <ErrorText>{formik.errors.password}</ErrorText>
        )}
      </FormGroup>

      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="confirmPassword">
          {t("common.confirmPassword")}
        </Label>
        <Input
          id="confirmPassword"
          type="password"
          placeholder={t("common.confirmPassword")}
          name={"confirmPassword"}
          value={formik.values.confirmPassword}
          onChange={(e) =>
            formik.setFieldValue("confirmPassword", e.target.value)
          }
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <ErrorText>{formik.errors.confirmPassword}</ErrorText>
        )}
      </FormGroup>

      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="role">
          {t("common.role")}
        </Label>
        <CustomSelect
          options={roleOptions}
          value={selectedRoleOption}
          onChange={(option: unknown) => {
            formik.setFieldValue(
              "roleId",
              (option as { value: number; label: string }).value
            );
          }}
        />
      </FormGroup>

      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="senior">
          {t("roles.senior")}
        </Label>
        <CustomSelect
          options={usersOptions}
          value={selectedSeniorOption}
          onChange={(option: unknown) => {
            formik.setFieldValue(
              "seniorId",
              (option as { value: number; label: string }).value
            );
          }}
        />
      </FormGroup>

      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="supervisor">
          {t("roles.supervisor")}
        </Label>
        <CustomSelect
          options={usersOptions}
          value={selectedSupervisorOption}
          onChange={(option: unknown) => {
            formik.setFieldValue(
              "supervisorId",
              (option as { value: number; label: string }).value
            );
          }}
        />
      </FormGroup>

      <FormGroup mb="20px">
        <Label display="block" mb="5px" htmlFor="areaManager">
          {t("roles.area-manager")}
        </Label>
        <CustomSelect
          options={usersOptions}
          value={selectedAreaManagerOption}
          onChange={(option: unknown) => {
            formik.setFieldValue(
              "areaManagerId",
              (option as { value: number; label: string }).value
            );
          }}
        />
      </FormGroup>

      {message && message.type !== "success" && (
        <Alert
          hasIcon={false}
          hasLink={false}
          isDismissible={false}
          solid={false}
          variant="contained"
          color={message.type}
        >
          {message.message}
        </Alert>
      )}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {onCancel && user && (
          <Button
            type="submit"
            variant="texted"
            onClick={() => onCancel(user.id)}
            marginRight="1.5rem"
            disabled={isSubmitting}
          >
            {t("common.delete")}
          </Button>
        )}
        <Button
          type={"submit"}
          color={"brand2"}
          fontSize={"16px"}
          disabled={
            isSubmitting ||
            formik.values.password !== formik.values.confirmPassword ||
            (!user && !formik.values.password)
          }
        >
          {t("common.confirm")}
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
