import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../hooks/useStores";
import { Navigate, useNavigate } from "react-router";

export const RedirectToUserBoard: React.FC = observer(() => {
  const { session } = useStores();

  const navigate = useNavigate();

  useEffect(() => {
    if (session.isLogged && session.user) {
      switch (session.user.role.id) {
        case "1":
          navigate("/admin/practices");
          break;
        case "2":
          navigate("/user/practices");
          break;
        case "3":
          navigate("/user/practices");
          break;
        case "4":
          navigate("/user/practices");
          break;
        case "5":
          navigate("/user/practices");
          break;
        default:
          break;
      }
    }
  }, [session, navigate]);

  if (session.isLogged && session.user) {
    switch (session.user.role.id) {
      case "0":
        navigate("/admin");
        break;

      default:
        break;
    }
  }

  return <Navigate to="/login" />;
});
