import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { Flex, Text } from "rebass";
import { SalesThreshold } from "../../types";
import { toCurrency } from "../../utils/conversions";
import { Tr, Td, Table, Thead, Th, Tbody } from "../Common/Table";

type Props = {
  thresholds: SalesThreshold[];
  onUpdateSalesThreshold?: (id: number) => any;
  onDeleteSalesThreshold?: (id: number) => any;
};
export const ThresholdTable: React.FC<Props> = ({
  thresholds,
  onUpdateSalesThreshold,
  onDeleteSalesThreshold,
}: Props) => {
  const { t } = useTranslation();

  const rows: any[] = useMemo(() => {
    let orderedThresholds = thresholds.sort((a, b) => a.quantity - b.quantity);
    return orderedThresholds.map((threshold) => {
      return (
        <Tr key={threshold.id}>
          <Td>{threshold.quantity}</Td>
          <Td>{toCurrency(threshold.bonus)}</Td>
          <Td>
            <HiOutlinePencil
              style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
              onClick={() =>
                onUpdateSalesThreshold && onUpdateSalesThreshold(threshold.id)
              }
            />
            <HiOutlineTrash
              style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
              onClick={() =>
                onDeleteSalesThreshold && onDeleteSalesThreshold(threshold.id)
              }
            />
          </Td>
        </Tr>
      );
    });
  }, [thresholds, onUpdateSalesThreshold, onDeleteSalesThreshold]);

  return (
    <Flex width={"100%"} flexDirection={"column"} fontSize={"13px"}>
      <Text
        fontSize={"11px"}
        fontWeight={500}
        style={{ textTransform: "uppercase" }}
      >
        {t("components.thresholdTable.salesThreshold")}
      </Text>
      <Table>
        <Thead>
          <Tr>
            <Th scope="col">{t("components.thresholdTable.salesThreshold")}</Th>
            <Th scope="col">{t("components.thresholdTable.bonus")}</Th>
            <Th scope="col">{t("components.thresholdTable.action")}</Th>
          </Tr>
        </Thead>
        <Tbody style={{ fontSize: "15px" }}>
          {rows.length > 0 ? rows : <></>}
        </Tbody>
      </Table>
    </Flex>
  );
};
