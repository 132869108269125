import {
  APIPracticeToPractice,
  practiceToPracticePayload,
  toCommission,
  toCompetition,
  toExtraSalesThreshold,
  toManagementCompetition,
  toObjective,
  toReversal,
  toRole,
  toSalesThreshold,
  toUpdatePracticePayload,
  toUser,
  toUserFromLogin,
} from "./../utils/conversions";
import {
  Commission,
  Competition,
  ExtraSalesThreshold,
  ManagementCompetition,
  Objective,
  Practice,
  Reversal,
  Role,
  SalesThreshold,
  UpdateOrCreateUserParams,
  User,
} from "./../types";
import env from "../config/env";
import dayjs from "dayjs";

let sessionToken: string | undefined;

const getSessionToken = () => {
  return sessionToken;
};
const setSession = (token: string) => {
  sessionToken = token;
};

const parseErrorMessage = async (response: Response) => {
  try {
    const data = await response.json();
    if (!data.ErrorMessage) {
      throw new Error();
    }
    return data.ErrorMessage;
  } catch (err) {
    return "";
  }
};

const makeApiRequest = async (
  method: "POST" | "GET" | "PATCH" | "DELETE" | "PUT",
  path: string,
  body?: any,
  omitToken: boolean = false
): Promise<any> => {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  if (sessionToken && !omitToken) {
    headers.append("Authorization", `Bearer ${sessionToken}`);
  }

  try {
    const response = await fetch(`${env.BACKEND_API_URL}${path}`, {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: headers,
    });

    if (!response.ok) {
      const message = await parseErrorMessage(response);
      throw new Error(`${response.status}: ${response.statusText}. ${message}`);
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.warn(`Failed request ${method} ${path}`, err);
    throw err;
  }
};

const login = async (email: string, password: string) => {
  const body = {
    identifier: email,
    password,
  };
  const response = await makeApiRequest("POST", "auth/local", body, true);

  localStorage.setItem("session", response.jwt);
  setSession(response.jwt);

  return toUserFromLogin(response.user);
};

const forgotPassword = async (email: string): Promise<boolean> => {
  return (await makeApiRequest("POST", "auth/forgot-password", { email }, true))
    .ok;
};

const verifyPasswordResetCodeAndUpdatePassword = async (
  passwordResetCode: string,
  newPassword: string
) => {
  return await makeApiRequest(
    "POST",
    "auth/reset-password",
    {
      code: passwordResetCode,
      password: newPassword,
      passwordConfirmation: newPassword,
    },
    true
  );
};

/* const updatePassword = async (
  email: string,
  passwordResetCode: string,
  newPassword: string
) => {
  return await makeApiRequest("POST", "auth/updatePassword", {
    email,
    passwordResetCode,
    newPassword,
  });
}; */

const logout = async () => {
  sessionToken = undefined;
};

const signupUser = async (user: any) => {
  const payload = {
    nominative: user.nominative,
    email: user.email,
    password: user.password,
  };

  return await makeApiRequest("POST", "auth/signup", payload, true);
};

const getUsers = async (): Promise<User[]> => {
  const result = await makeApiRequest("GET", `imc-role/users`);

  if (result.data) return result.data.map((result: any) => toUser(result));
  return [];
};

const getCurrentUser = async (): Promise<User | null> => {
  const sessionData = localStorage.getItem("session");
  if (!sessionData) return null;
  setSession(JSON.parse(sessionData).token);
  const result = await makeApiRequest("GET", `users/me`);

  if (result.data) return toUser(result.data);
  else return null;
};

const createUser = async (
  user: UpdateOrCreateUserParams
): Promise<User | null> => {
  const payload = {
    username: user.name + " " + user.surname + " " + Date.now(),
    name: user.name,
    surname: user.surname,
    phone_number: user.phoneNumber,
    email: user.email,
    password: user.password,
    role: user.usersPermissionsId,
    imc_role: user.roleId,
    senior: user.seniorId,
    supervisor: user.supervisorId,
    manager: user.areaManagerId,
  };

  const result = await makeApiRequest(
    "POST",
    `users?populate=role,imc_role`,
    payload
  );

  if (result.data) return toUser(result.data);
  return null;
};

const updateUser = async (
  userId: number,
  email?: string,
  password?: string,
  user?: UpdateOrCreateUserParams
) => {
  let payload: any = {};
  if (user) {
    payload = {
      name: user.name,
      surname: user.surname,
      phone_number: user.phoneNumber,
      email: user.email,
      password:
        user.password === user.confirmPassword ? user.password : undefined,
      imc_role: user.roleId,
      role: user.usersPermissionsId,
      senior: user.seniorId,
      supervisor: user.supervisorId,
      manager: user.areaManagerId,
    };
  } else {
    if (email) {
      payload.email = email;
    }
    if (password) {
      payload.password = password;
    }
  }

  return await makeApiRequest("PUT", "users/" + userId, { data: payload });
};

const deleteUser = async (id: User["id"]): Promise<boolean> => {
  await makeApiRequest("DELETE", `users/${id}`);

  return true;
};

const countUsers = async (): Promise<number> => {
  return await makeApiRequest("GET", "users/count");
};

const getTotalPayments = async (
  month: number,
  year: number
): Promise<number> => {
  return await makeApiRequest("GET", `users/${year}/${month}/totalPayments`);
};

const getUsersWithCommissions = async (
  year: number,
  month: number
): Promise<User[]> => {
  const result = await makeApiRequest(
    "GET",
    `users/commissions/${year}/${month}`
  );

  if (result.data) return result.data.map((result: any) => toUser(result));
  return [];
};

const getUserCommissions = async (
  userId: number,
  year: number,
  month: number
): Promise<Commission | null> => {
  const result = await makeApiRequest(
    "GET",
    `users/${userId}/commissions/${year}/${month}`
  );

  if (result.data) return toCommission(result.data);
  return null;
};

//---------------------------------- PRACTICES ----------------------------------

const getUserPractices = async (
  user?: User,
  startDate?: Date,
  endDate?: Date
): Promise<Practice[]> => {
  let queryPostfix = "";
  if (user) {
    queryPostfix += `/user/${user.id}`;
  }
  if (startDate && endDate) {
    queryPostfix += `?startDate=${dayjs(startDate).format(
      "YYYY-MM-DD"
    )}&endDate=${dayjs(endDate).format("YYYY-MM-DD")}`;
  }
  const result = await makeApiRequest("GET", `practices${queryPostfix}`);
  if (result.data) {
    return result.data.map((data: any) => {
      if (data.attributes.user) return APIPracticeToPractice(data);
      else return {};
    });
  }
  return [];
};

const createPractice = async (practice: Practice): Promise<Practice | null> => {
  const result = await makeApiRequest(
    "POST",
    `practices`,
    practiceToPracticePayload(practice)
  );
  if (result) return APIPracticeToPractice(result, practice.user);
  return null;
};

const updatePractice = async (
  id: number,
  data: any
): Promise<Practice | null> => {
  const result = await makeApiRequest(
    "PUT",
    `practices/${id}`,
    toUpdatePracticePayload(data)
  );
  if (result) return APIPracticeToPractice(result, result.user);
  return null;
};

//---------------------------------- ROLES ----------------------------------
const getImcRoles = async (
  populate: ("objectives" | "users" | "role")[] = []
): Promise<Role[]> => {
  let toAppend = "";

  populate.map((item, index) => {
    if (index === 0) {
      toAppend += "?populate=";
    } else {
      toAppend += ",";
    }
    toAppend += item;

    return item;
  });

  const result = await makeApiRequest("GET", "imc-roles" + toAppend);
  if (result.data)
    return result.data.map((data: any) => {
      if (data) return toRole(data);
      else return {};
    });
  return [];
};

const canUpdateRole = async (
  userId: number,
  updateType: "upgrade" | "downgrade"
): Promise<boolean> => {
  const result = await makeApiRequest(
    "GET",
    updateType === "upgrade"
      ? `users/${userId}/canUpgradeRole`
      : `users/${userId}/canDowngradeRole`
  );

  return !!result;
};

const updateUserRole = async (
  userId: number,
  updateType: "upgrade" | "downgrade"
): Promise<User | null> => {
  const result = await makeApiRequest(
    "POST",
    updateType === "upgrade"
      ? `users/${userId}/upgradeRole`
      : `users/${userId}/downgradeRole`
  );

  if (result.data) return toUser(result.data);
  return null;
};

//---------------------------------- SALES THRESHOLDS ----------------------------------
const createSalesThreshold = async (
  salesThreshold: SalesThreshold,
  competitionId: number
): Promise<SalesThreshold | null> => {
  const payload = {
    quantity: salesThreshold.quantity,
    bonus: salesThreshold.bonus,
    competition: competitionId,
  };
  const result = await makeApiRequest("POST", `sales-thresholds/`, {
    data: payload,
  });

  if (result.data) return toSalesThreshold(result.data);
  return null;
};

const updateSalesThreshold = async (
  salesThreshold: SalesThreshold,
  competitionId: number
): Promise<SalesThreshold | null> => {
  const payload = {
    ...salesThreshold,
    competition: competitionId,
  };
  const result = await makeApiRequest(
    "PUT",
    `sales-thresholds/${salesThreshold.id}/`,
    { data: payload }
  );

  if (result.data) return toSalesThreshold(result.data);
  return null;
};

const deleteSalesThreshold = async (
  salesThresholdId: number
): Promise<SalesThreshold | null> => {
  const result = await makeApiRequest(
    "DELETE",
    `sales-thresholds/${salesThresholdId}/`
  );

  if (result.data) return toSalesThreshold(result.data);
  return null;
};

//---------------------------------- EXTRA SALES THRESHOLDS ----------------------------------
const createExtraSalesThreshold = async (
  extraSalesThreshold: ExtraSalesThreshold,
  competitionId: number
): Promise<ExtraSalesThreshold | null> => {
  const payload = {
    quantity: extraSalesThreshold.quantity,
    bonus: extraSalesThreshold.bonus,
    competition: competitionId,
  };
  const result = await makeApiRequest("POST", `extra-sales-thresholds/`, {
    data: payload,
  });

  if (result.data) return toExtraSalesThreshold(result.data);
  return null;
};

const updateExtraSalesThreshold = async (
  extraSalesThreshold: SalesThreshold,
  competitionId: number
): Promise<ExtraSalesThreshold | null> => {
  const payload = {
    ...extraSalesThreshold,
    competition: competitionId,
  };
  const result = await makeApiRequest(
    "PUT",
    `extra-sales-thresholds/${extraSalesThreshold.id}/`,
    { data: payload }
  );

  if (result.data) return toExtraSalesThreshold(result.data);
  return null;
};

const deleteExtraSalesThreshold = async (
  extraSalesThresholdId: number
): Promise<ExtraSalesThreshold | null> => {
  const result = await makeApiRequest(
    "DELETE",
    `extra-sales-thresholds/${extraSalesThresholdId}/`
  );

  if (result.data) return toExtraSalesThreshold(result.data);
  return null;
};

//---------------------------------- MANAGEMENT COMPETITIONS ----------------------------------
const createManagementCompetition = async (
  managementCompetition: ManagementCompetition,
  competitionId: number
): Promise<ManagementCompetition | null> => {
  const { id, ...competition } = managementCompetition;
  const payload = {
    ...competition,
    competition: competitionId,
  };

  const result = await makeApiRequest("POST", `management-competitions`, {
    data: payload,
  });

  if (result.data) return toManagementCompetition(result.data);
  return null;
};

const updateManagementCompetition = async (
  managementCompetition: ManagementCompetition,
  competitionId: number
): Promise<ManagementCompetition | null> => {
  const payload = {
    ...managementCompetition,
    competition: competitionId,
  };

  const result = await makeApiRequest(
    "PUT",
    `management-competitions/${managementCompetition.id}`,
    { data: payload }
  );

  if (result.data) return toManagementCompetition(result.data);
  return null;
};

const deleteManagementCompetition = async (
  managementCompetitionId: number
): Promise<ManagementCompetition | null> => {
  const result = await makeApiRequest(
    "DELETE",
    `management-competitions/${managementCompetitionId}`
  );

  if (result.data) return toManagementCompetition(result.data);
  return null;
};
//---------------------------------- OBJECTIVES ----------------------------------
const getUserObjectives = async (userId: number): Promise<Objective[]> => {
  const result = await makeApiRequest("GET", `objectives/user/${userId}`);

  if (result.data) return result.data.map((item: any) => toObjective(item));
  return [];
};

const createObjective = async (
  roleId: number,
  text: string
): Promise<Objective | null> => {
  const payload = {
    data: {
      imc_role: roleId,
      text,
    },
  };

  const result = await makeApiRequest("POST", `objectives`, payload);

  if (result.data) return toObjective(result.data);
  return null;
};

const updateObjective = async (
  id: number,
  text: string
): Promise<Objective | null> => {
  const result = await makeApiRequest("PUT", `objectives/${id}`, {
    data: { text },
  });
  if (result.data) return toObjective(result.data);
  return null;
};

const editObjectiveCheck = async (
  objectiveId: number,
  userId: number,
  completed: boolean
) => {
  const payload = {
    completed,
  };
  const result = await makeApiRequest(
    "PATCH",
    `objectives/${objectiveId}/user/${userId}`,
    payload
  );
  if (result.data) return toObjective(result.data);
  return null;
};

const deleteObjective = async (id: number): Promise<Objective | null> => {
  const result = await makeApiRequest("DELETE", `objectives/${id}`);
  if (result.data) return toObjective(result.data);
  return null;
};

//---------------------------------- COMPETITIONS ----------------------------------
const createCompetition = async (
  startDate: Date,
  endDate: Date,
  roleId: number
): Promise<Competition | null> => {
  const payload = {
    startDate,
    endDate,
    imc_role: roleId,
  };

  const result = await makeApiRequest("POST", `competitions`, {
    data: payload,
  });

  if (result.data) return toCompetition(result.data);
  return null;
};

const updateCompetition = async (
  newCompetition: Competition,
  roleId: number
): Promise<Competition | null> => {
  const payload = {
    ...newCompetition,
    imc_role: roleId,
  };

  const result = await makeApiRequest(
    "PUT",
    `competitions/${newCompetition.id}`,
    { data: payload }
  );

  if (result.data) return toCompetition(result.data);
  return null;
};

const deleteCompetition = async (
  competitionId: number
): Promise<Competition | null> => {
  const result = await makeApiRequest(
    "DELETE",
    `competitions/${competitionId}`
  );

  if (result.data) return toCompetition(result.data);
  return null;
};

//---------------------------------- REVERSALS ----------------------------------
const createOrUpdateReversal = async (
  amount: Reversal["amount"],
  date: Reversal["date"],
  userId: User["id"],
  id?: Reversal["id"]
): Promise<Reversal | null> => {
  let result: any = null;
  const payload = {
    amount,
    date,
    id,
    users_permissions_user: userId,
  };

  if (id) {
    result = await makeApiRequest("PUT", `reversals/${id}`, {
      data: payload,
    });
  } else {
    result = await makeApiRequest("POST", `reversals`, {
      data: payload,
    });
  }

  if (result.data) return toReversal(result.data);
  return null;
};

const api = {
  getSessionToken,
  setSession,
  login,
  forgotPassword,
  verifyPasswordResetCodeAndUpdatePassword,
  logout,
  signupUser,
  getUsers,
  getCurrentUser,
  updateUser,
  deleteUser,
  createUser,
  countUsers,
  getTotalPayments,
  getUsersWithCommissions,
  getUserCommissions,
  getUserPractices,
  createPractice,
  updatePractice,
  getImcRoles,
  canUpdateRole,
  updateUserRole,
  createSalesThreshold,
  updateSalesThreshold,
  deleteSalesThreshold,
  createExtraSalesThreshold,
  updateExtraSalesThreshold,
  deleteExtraSalesThreshold,
  createManagementCompetition,
  updateManagementCompetition,
  deleteManagementCompetition,
  getUserObjectives,
  createObjective,
  updateObjective,
  editObjectiveCheck,
  deleteObjective,
  createCompetition,
  updateCompetition,
  deleteCompetition,
  createOrUpdateReversal,
};

export default api;
