import { FC } from "react";
import logoIMCImage from "../../../assets/imc_logo.png";
import logoFotovoltaicoSempliceImage from "../../../assets/fotovoltaticoSemplice_logo.png";
import {
  StyledMedia,
  StyledMediaBody,
  StyledImage,
  StyledSignin,
} from "./style";
import ChangePasswordForm from "../ChangePasswordForm";
import { AlertMessage } from "../../../types";

type Props = {
  isSubmitting: boolean;
  message?: AlertMessage;
  onSubmit: (password: string) => void;
};

const ChangePasswordContainer: FC<Props> = ({
  message,
  isSubmitting,
  onSubmit,
}) => {
  return (
    <StyledMedia>
      <StyledMediaBody
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledImage>
          <img src={logoIMCImage} alt="IMCHolding" />
        </StyledImage>
        <StyledImage>
          <img
            src={logoFotovoltaicoSempliceImage}
            alt="Fotovoltaico Semplice"
          />
        </StyledImage>
      </StyledMediaBody>
      <StyledSignin>
        <ChangePasswordForm
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          message={message}
        />
      </StyledSignin>
    </StyledMedia>
  );
};

export default ChangePasswordContainer;
