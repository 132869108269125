import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  ModalTitle,
} from "../UI/Modal";

type Props = {
  title?: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  themeColor?: string;
  size?: "md" | "xl" | "lg" | "sm";
  children?: any;
};
export const CenteredModal: React.FC<Props> = ({
  title,
  isOpen,
  setIsOpen,
  themeColor,
  size,
  children,
}: Props) => {
  return (
    <>
      <Modal
        show={isOpen}
        onClose={() => setIsOpen(false)}
        size={size || "md"}
        centered
      >
        <ModalHeader style={{ backgroundColor: themeColor || "#563D7C" }}>
          <ModalTitle style={{ color: "white" }}>{title}</ModalTitle>
          <ModalClose
            onClose={() => setIsOpen(false)}
            style={{ color: "white" }}
          >
            x
          </ModalClose>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </>
  );
};
