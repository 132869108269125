import i18n from "../../i18n";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { SignInScreen } from "../SignIn";
import { useEffect, useState } from "react";

import { Stores } from "../../types";
import { StoresContext } from "../../contexts";
import { SessionStore } from "../../stores/Session";

import { ForgotPasswordScreen } from "../ForgotPassword";
import { ChangePasswordScreen } from "../ChangePassword";
import { observer } from "mobx-react";
import { PrivateRoute } from "../../components/Common/PrivateRoute";
import { AppLayout } from "../AppLayout";
import { AdminPractices } from "../AdminPractices";
import { RedirectToUserBoard } from "../RedirectToUserBoard";
import { PracticesStore } from "../../stores/PracticesStore";
import { AdminUsersManagement } from "../AdminUsersManagement";
import { AdminRolesManagement } from "../AdminRolesManagement";
import { CommonDashboard } from "../CommonDashboard";
import { UserProfile } from "../UserProfile";
import { Payments } from "../Payments";

const Root: React.FC = observer((props) => {
  const [stores] = useState<Stores>(() => {
    const root: Stores = {} as Stores;

    root.session = new SessionStore(root);
    root.practices = new PracticesStore(root);

    return root;
  });

  useEffect(() => {
    if (!stores.session.isInitialized) {
      stores.session.initialize();
    }
  }, [stores.session, stores.session.isInitialized]);

  useEffect(() => {
    i18n.changeLanguage("it");
  }, []);

  if (!stores.session.isInitialized) {
    return <></>;
  }

  return (
    <StoresContext.Provider value={stores}>
      <Router>
        <Routes>
          <Route path="/login" element={<SignInScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/change-password" element={<ChangePasswordScreen />} />

          <Route element={<AppLayout />}>
            <Route element={<PrivateRoute rolesNames={["admin"]} />}>
              <Route path={"admin/practices"} element={<AdminPractices />} />
              <Route
                path={"admin/users-management/:itemId"}
                element={<AdminUsersManagement />}
              />
              <Route
                path={"admin/roles-management/:itemId"}
                element={<AdminRolesManagement />}
              />
              <Route
                path={"admin/users-management"}
                element={<AdminUsersManagement />}
              />
              <Route
                path={"admin/roles-management"}
                element={<AdminRolesManagement />}
              />
              <Route path={"admin/payments"} element={<Payments />} />
              <Route path={"admin/*"} element={<AdminPractices />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  rolesNames={[
                    "junior",
                    "senior",
                    "supervisor",
                    "area-manager",
                  ]}
                />
              }
            >
              <Route path={"user/practices"} element={<CommonDashboard />} />
              <Route path={"user/*"} element={<AdminPractices />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path={"profile"} element={<UserProfile />} />
            </Route>
          </Route>
          <Route path="*" element={<RedirectToUserBoard />} />
        </Routes>
      </Router>
    </StoresContext.Provider>
  );
});

export default styled(Root)`
  & {
    height: 100vh;
  }
`;
