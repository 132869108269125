import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { ErrorText } from "./Forms/ErrorText";
import * as Yup from "yup";
import { FormGroup, Input, Label } from "./Forms";
import { ManagementCompetition } from "../../types";

type Props = {
  initialValues?: ManagementCompetition;
  onSubmit?: (
    juniorClosedContracts: number,
    bonus: number,
    monthsAfterCreation: number
  ) => any;
  onCancel?: () => any;
  isSubmitting?: boolean;
};

export const ManagementCompetitionForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    juniorClosedContracts: Yup.number().required(
      t(
        "messages.errors.managementCompetitionForm.juniorClosedContractsRequired"
      )
    ),
    monthsAfterCreation: Yup.number().required(
      t("messages.errors.managementCompetitionForm.monthsAfterCreationRequired")
    ),
    bonus: Yup.number().required(
      t("messages.errors.managementCompetitionForm.bonusRequired")
    ),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          monthsAfterCreation: 1,
          juniorClosedContracts: 1,
          bonus: 50,
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        onSubmit &&
        values.bonus &&
        values.juniorClosedContracts &&
        values.monthsAfterCreation
      )
        onSubmit(
          values.juniorClosedContracts,
          values.bonus,
          values.monthsAfterCreation
        );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* MonthsAfterCreation */}
        {formik.touched && formik.errors.monthsAfterCreation && (
          <ErrorText>{formik.errors.monthsAfterCreation}</ErrorText>
        )}
        <FormGroup mb={"20px"}>
          <Label display={"block"} mb={"5px"} htmlFor={"monthsAfterCreation"}>
            {t("components.managementCompetitionForm.monthsAfterCreation")}
          </Label>
          <Input
            id={"monthsAfterCreation"}
            name={"monthsAfterCreation"}
            type={"number"}
            value={formik.values.monthsAfterCreation || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t(
              "components.managementCompetitionForm.monthsAfterCreation"
            )}
          />
        </FormGroup>
        {/* JuniorClosedContracts */}
        {formik.touched && formik.errors.juniorClosedContracts && (
          <ErrorText>{formik.errors.juniorClosedContracts}</ErrorText>
        )}
        <FormGroup mb={"20px"}>
          <Label display={"block"} mb={"5px"} htmlFor={"juniorClosedContracts"}>
            {t("components.managementCompetitionForm.juniorClosedContracts")}
          </Label>
          <Input
            id={"juniorClosedContracts"}
            name={"juniorClosedContracts"}
            type={"number"}
            value={formik.values.juniorClosedContracts || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t(
              "components.managementCompetitionForm.juniorClosedContracts"
            )}
          />
        </FormGroup>
        {/* Bonus */}
        {formik.touched && formik.errors.bonus && (
          <ErrorText>{formik.errors.bonus}</ErrorText>
        )}
        <FormGroup mb={"20px"}>
          <Label display={"block"} mb={"5px"} htmlFor={"bonus"}>
            {t("components.managementCompetitionForm.bonus")}
          </Label>
          <Input
            id={"bonus"}
            name={"bonus"}
            type={"number"}
            value={formik.values.bonus || undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t("components.managementCompetitionForm.bonus")}
          />
        </FormGroup>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant={"outlined"}
            fontSize={"16px"}
            disabled={isSubmitting}
            mr={"1rem"}
            onClick={onCancel}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type={"submit"}
            color={"brand2"}
            fontSize={"16px"}
            disabled={isSubmitting}
          >
            {t("common.confirm")}
          </Button>
        </div>
      </div>
    </form>
  );
};
