import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../components/Common/Content";
import ForgotPassword from "../../components/Auth/ForgotPassword";
import { useStores } from "../../hooks/useStores";
import { AlertMessage } from "../../types";

export const ForgotPasswordScreen: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();
  const [message, setMessage] = useState<AlertMessage>();

  const onForgotPassword = useCallback(
    async (username: string) => {
      try {
        const status = await session.forgotPassword(username);
        if (!status) {
          throw new Error();
        }
        setMessage({
          type: "success",
          message: t("messages.successes.forgotPasswordForm.sentEmail"),
        });
      } catch (e) {
        setMessage({
          type: "danger",
          message: t("messages.errors.forgotPasswordForm.wrongEmail"),
        });
      }
    },
    [session, t]
  );

  return (
    <Content fullHeight>
      <ForgotPassword
        onSubmit={onForgotPassword}
        isSubmitting={session.isSubmitting}
        message={message}
      />
    </Content>
  );
});
