import Label from "../Label";
import ContactItem from "../ContactItem";
import { ContactItemType, ContactSection } from "../../../../types";
import { useMemo } from "react";

type Props = {
  items: ContactItemType[];
  activeId?: number;
  clickHandler?: (item: ContactItemType) => void;
  disableSections?: boolean;
};

const AllContacts: React.FC<Props> = ({
  items,
  activeId,
  clickHandler,
  disableSections,
}: Props) => {
  const sections: ContactSection[] = useMemo(() => {
    if (!items) return [];
    let toReturn: ContactSection[] = [];

    //Sort the items by their primaryText
    let tempItems = [...items];
    tempItems.sort((a, b) => {
      let fa = a.primaryText.toLowerCase(),
        fb = b.primaryText.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    //Create a section for every new initial letter
    let currentLetter = "";
    tempItems.map((item) => {
      if (item.primaryText[0] !== currentLetter || currentLetter === "") {
        toReturn.push({ label: item.primaryText[0], items: [] });
        currentLetter = item.primaryText[0];
      }
      return toReturn[toReturn.length - 1].items.push(item);
    });

    return toReturn;
  }, [items]);

  return (
    <>
      {disableSections ? (
        items?.map((item, index) => (
          <ContactItem
            id={index.toString()}
            item={{
              id: item.id,
              color: item.color,
              primaryText: item.primaryText,
              secondaryText: item.secondaryText,
            }}
            activeId={activeId}
            onClick={clickHandler}
            key={index}
          />
        ))
      ) : (
        <>
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <Label size="lg">{section.label}</Label>
              {section.items.map((item, itemIndex) => (
                <ContactItem
                  id={item.id}
                  item={{
                    id: item.id,
                    color: item.color,
                    primaryText: item.primaryText,
                    secondaryText: item.secondaryText,
                  }}
                  activeId={activeId}
                  onClick={clickHandler}
                  key={itemIndex}
                />
              ))}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default AllContacts;
