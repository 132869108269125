import i18n from "../i18n";
import {
  APIPractice,
  Commission,
  Competition,
  ContactItemType,
  ExtraSalesThreshold,
  JuniorBonus,
  ManagementCompetition,
  Objective,
  Practice,
  PracticeState,
  Reversal,
  Role,
  SalesThreshold,
  SeniorBonus,
  UpdatePracticePayload,
  User,
} from "../types";
import { formatName } from "./methods";

export const toCommission = (data: any): Commission => {
  return {
    commissions: data.attributes.commissions,
    commissionsInPayment: data.attributes.commissionsInPayment,
    bonuses: data.attributes.bonuses,
    totalCommissions: data.attributes.totalCommissions,
    sells: data.attributes.sells,
    sellsInPayment: data.attributes.sellsInPayment,
    sellsTotalAmount: data.attributes.sellsTotalAmount,
    sellsInPaymentTotalAmount: data.attributes.sellsInPaymentTotalAmount,
    juniorBonuses: data.attributes.juniorBonuses?.data.map((bonus: any) =>
      toJuniorBonus(bonus)
    ),
    totalJuniorBonus: data.attributes.totalJuniorBonus,
    seniorBonuses: data.attributes.seniorBonuses?.data.map((bonus: any) =>
      toSeniorBonus(bonus)
    ),
    totalSeniorBonus: data.attributes.totalSeniorBonus,
    reversal:
      data.attributes.reversal && toReversal(data.attributes.reversal.data),
  };
};

export const toReversal = (data: any): Reversal => {
  return {
    id: data.id,
    amount: data.attributes.amount,
    date: data.attributes.date,
  };
};

export const toJuniorBonus = (data: any): JuniorBonus => {
  return {
    junior: toUser(data.attributes.junior.data),
    practices: data.attributes.practices.data.map((practice: any) =>
      APIPracticeToPractice(practice)
    ),
    bonus: data.attributes.bonus,
  };
};

export const toSeniorBonus = (data: any): SeniorBonus => {
  return {
    senior: toUser(data.attributes.senior.data),
    practices: data.attributes.practices.data.map((practice: any) =>
      APIPracticeToPractice(practice)
    ),
    commissionsBonus: data.attributes.commissionsBonus,
  };
};

export const toUser = (data: any): User => {
  let trainedUsers: User[] =
    data.attributes.supervised_users?.data?.map((user: any) => toUser(user)) ||
    [];
  //Merge supervised users and managed users into one array
  Array.prototype.push.apply(
    trainedUsers,
    data.attributes.managed_users?.data?.map((user: any) => toUser(user)) || []
  );
  //Merge also with formed users
  Array.prototype.push.apply(
    trainedUsers,
    data.attributes.formed_users?.data?.map((user: any) => toUser(user)) || []
  );

  return {
    id: data.id,
    email: data.attributes.email,
    name: data.attributes.name,
    surname: data.attributes.surname,
    color: data.attributes.color,
    role: data.attributes.imc_role
      ? toRole(data.attributes.imc_role.data)
      : toRole({ id: "-1", attributes: { name: "no_role" } }), //TODO: remove
    phoneNumber: data.attributes.phone_number,
    practices: data.attributes.practices || [],
    senior: data.attributes.senior,
    supervisor: data.attributes.supervisor,
    manager: data.attributes.manager,
    trainedJuniors: trainedUsers.filter((user) => user.role.name === "junior"),
    trainedSeniors: trainedUsers.filter((user) => user.role.name === "senior"),
    commissions: data.attributes.commissions
      ? toCommission(data.attributes.commissions.data)
      : undefined,
  };
};

export const toUserFromLogin = (data: any): User => {
  return {
    id: data.id,
    email: data.email,
    name: data.name,
    surname: data.surname,
    color: data.color,
    role: data.imc_role
      ? toRoleFromLogin(data.imc_role)
      : toRoleFromLogin({ id: "-1", name: "no_role" }), //TODO: remove
    phoneNumber: data.phone_number,
    practices: data.practices || [],
    senior: data.senior,
    supervisor: data.supervisor,
    manager: data.manager,
    trainedJuniors: [],
    trainedSeniors: [],
  };
};

export const toRole = (data: any): Role => {
  return {
    id: data.id.toString(),
    usersPermissionsId: data.attributes.role?.data?.id?.toString(),
    name: data.attributes.name,
    commissionPercentage: data.attributes.commissionPercentage,
    objectives:
      (data.attributes.objectives?.data &&
        data.attributes.objectives.data.map((objective: any) =>
          toObjective(objective)
        )) ||
      [],
    competitions:
      (data.attributes.competitions?.data &&
        data.attributes.competitions.data.map((competition: any) =>
          toCompetition(competition)
        )) ||
      [],
  };
};
export const toRoleFromLogin = (data: any): Role => {
  return {
    id: data.id.toString(),
    usersPermissionsId: data.role?.id?.toString(),
    name: data.name,
    commissionPercentage: data.commissionPercentage || 0,
    objectives:
      (data.objectives?.data &&
        data.objectives.map((objective: any) => toObjective(objective))) ||
      [],
    competitions:
      (data.competitions?.data &&
        data.competitions.map((competition: any) =>
          toCompetition(competition)
        )) ||
      [],
  };
};

export const toCompetition = (data: any): Competition => {
  return {
    id: data.id,
    startDate: new Date(data.attributes.startDate),
    endDate: new Date(data.attributes.endDate),
    thresholds:
      (data.attributes.sales_thresholds?.data &&
        data.attributes.sales_thresholds.data.map((threshold: any) =>
          toSalesThreshold(threshold)
        )) ||
      [],
    extraThresholds:
      (data.attributes.extra_sales_thresholds?.data &&
        data.attributes.extra_sales_thresholds.data.map((threshold: any) =>
          toExtraSalesThreshold(threshold)
        )) ||
      [],
    managementCompetitions:
      (data.attributes.management_competitions?.data &&
        data.attributes.management_competitions.data.map((competition: any) =>
          toManagementCompetition(competition)
        )) ||
      [],
  };
};

export const toSalesThreshold = (data: any): SalesThreshold => {
  return {
    id: data.id,
    bonus: data.attributes.bonus,
    quantity: data.attributes.quantity,
  };
};
export const toExtraSalesThreshold = (data: any): ExtraSalesThreshold => {
  return {
    id: data.id,
    bonus: data.attributes.bonus,
    quantity: data.attributes.quantity,
  };
};
export const toManagementCompetition = (data: any): ManagementCompetition => {
  return {
    id: data.id,
    bonus: data.attributes.bonus,
    monthsAfterCreation: data.attributes.monthsAfterCreation,
    juniorClosedContracts: data.attributes.juniorClosedContracts,
  };
};

export const toObjective = (data: any): Objective => {
  return {
    id: data.id,
    completed: data.attributes.completed || false,
    text: data.attributes.text,
  };
};

export const toUserComplete = (
  user?: User,
  pratices?: Practice[]
): User | undefined => {
  if (!user) return undefined;
  let toReturn: User = { ...user };
  if (pratices) toReturn.practices = pratices;

  return toReturn;
};

export const toPracticeState = (data: string): PracticeState => {
  switch (data) {
    case "toValidate":
      return data;
    case "validated":
      return data;
    case "rejected":
      return data;

    default:
      return "toValidate";
  }
};

export const APIPracticeToPractice = (
  apiPractice: APIPractice,
  user?: User
): Practice => {
  return {
    id: apiPractice.id,
    customer: apiPractice.attributes.customer,
    date: new Date(apiPractice.attributes.date),
    state: toPracticeState(apiPractice.attributes.state),
    totalAmount: apiPractice.attributes.totalAmount,
    description: apiPractice.attributes.description,
    user: user || toUser(apiPractice.attributes.user.data),
    paymentMonth:
      apiPractice.attributes.paymentMonth &&
      new Date(apiPractice.attributes.paymentMonth),
  };
};

export const practiceToPracticePayload = (practice: Practice): any => {
  return {
    data: {
      user: practice.user?.id,
      customer: practice.customer,
      date: practice.date,
      totalAmount: practice.totalAmount,
      state: practice.state || "toValidate",
      description: practice.description,
    },
  };
};

export const userToContactItemType = (user: User): ContactItemType => {
  return {
    id: user.id,
    primaryText: formatName(user.name, user.surname),
    secondaryText: i18n.t("roles." + user.role.name),
    color: user.color,
    image: undefined,
  };
};

export const roleToContactItemType = (role: Role): ContactItemType => {
  return {
    id: role.id,
    primaryText: i18n.t("roles." + role.name),
    image: undefined,
  };
};

export const toUpdatePracticePayload = (data: any): UpdatePracticePayload => {
  let toReturn: UpdatePracticePayload = { data: {} };

  if (data.state) toReturn.data.state = data.state;
  if (data.paymentMonth) toReturn.data.paymentMonth = data.paymentMonth;
  if (data.state === "validated") toReturn.data.validationDate = new Date();

  return toReturn;
};

export const dateToDatePickerFormat = (date: Date): string => {
  return (
    date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  );
};

export const dateToMonthPickerFormat = (date: Date): string => {
  return (
    date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0")
  );
};

export const toCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  });
  return formatter.format(value);
};

export const toFormattedDate = (date: Date): string => {
  return date.toLocaleDateString();
};
