import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { StyledWrap, StyledTitle, StyledDesc, StyledBottomText } from "./style";
import { hasKey } from "../../../utils/methods";
import { FormGroup, Input, Label } from "../../Common/Forms";
import { Anchor } from "../../Common/Anchor";
import { Button } from "../../Common/Button";
import { useTranslation } from "react-i18next";
import { Alert } from "../../Common/Alert";
import { AlertMessage } from "../../../types";

interface IFormValues {
  email: string;
}

type Props = {
  message?: AlertMessage;
  isSubmitting: boolean;
  onSubmit: (username: string) => void;
};

const ForgotPasswordForm: FC<Props> = ({ message, isSubmitting, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (data: IFormValues) => {
      onSubmit(data.email);
    },
    [onSubmit]
  );

  return (
    <StyledWrap>
      <StyledTitle>{t("components.forgotPasswordForm.title")}</StyledTitle>
      <StyledDesc>{t("components.forgotPasswordForm.description")}</StyledDesc>
      {message && (
        <Alert
          hasIcon={false}
          hasLink={false}
          isDismissible={false}
          solid={false}
          variant="contained"
          color={message.type}
        >
          {message.message}
        </Alert>
      )}
      <form action="#" onSubmit={handleSubmit(handleOnSubmit)} noValidate>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="email">
            {t("components.forgotPasswordForm.email")}
          </Label>
          <Input
            type="email"
            id="email"
            placeholder="nome@email.com"
            feedbackText={errors?.email?.message}
            state={hasKey(errors, "email") ? "error" : "success"}
            showState={!!hasKey(errors, "email")}
            {...register("email", {
              required: t(
                "messages.formValidations.common.emailRequired"
              ).toString(),
            })}
          />
        </FormGroup>

        <Button type="submit" color="brand2" fullwidth disabled={isSubmitting}>
          {t("components.forgotPasswordForm.resetPassword")}
        </Button>

        <StyledBottomText>
          <Anchor path="/login" color="brand2" fontSize="15px">
            {t("common.backToLogin")}
          </Anchor>
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default ForgotPasswordForm;
