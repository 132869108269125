import { useTranslation } from "react-i18next";
import { Practice, PracticeState } from "../../types";
import { Text } from "rebass";
import { Button } from "./Button";
import { formatName } from "../../utils/methods";
import { Textarea } from "./Forms/Textarea";
import { Line } from "./Line";
import { useCallback } from "react";
import { toCurrency, toFormattedDate } from "../../utils/conversions";
import { getNextMonthDate } from "../../utils/others";

type Props = {
  practice: Practice;
  onSubmit?: (state: PracticeState, paymentMonth?: Date) => void;
  onCancel?: () => void;
  isSubmitting?: boolean;
};

export const PracticeSummary: React.FC<Props> = ({
  practice,
  onSubmit,
  onCancel,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation();
  const onValidate = useCallback(
    (paymentMonth: Date) => {
      onSubmit && onSubmit("validated", paymentMonth);
    },
    [onSubmit]
  );
  const onReject = useCallback(() => {
    onSubmit && onSubmit("rejected");
  }, [onSubmit]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DataRow
        attribute={t("components.practiceForm.customer")}
        value={practice.customer}
      />
      <Line width={"60%"} />
      <DataRow
        attribute={t("components.practiceForm.user")}
        value={formatName(practice.user?.name, practice.user?.surname)}
      />
      <Line width={"60%"} />
      <DataRow
        attribute={t("components.practiceForm.date")}
        value={toFormattedDate(practice.date)}
      />
      <Line width={"60%"} />
      <DataRow
        attribute={t("components.practiceForm.totalAmount")}
        value={toCurrency(practice.totalAmount)}
      />
      <Line width={"60%"} />
      <DataRow
        attribute={t("components.practiceForm.description")}
        value={practice.description}
        isTextArea
      />
      {onSubmit && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Button variant={"outlined"} mr={"1rem"} onClick={onCancel}>
              {t("common.cancel")}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color={"brand2"}
              fontSize={"16px"}
              disabled={isSubmitting}
              mb={"0.5rem"}
              onClick={() => onValidate(new Date())}
            >
              {t("components.practiceForm.validateThisMonth")}
            </Button>
            <Button
              color={"brand2"}
              fontSize={"16px"}
              disabled={isSubmitting}
              mb={"0.5rem"}
              onClick={() => onValidate(getNextMonthDate())}
            >
              {t("components.practiceForm.validateNextMonth")}
            </Button>
            <Button
              color={"danger"}
              fontSize={"16px"}
              ml={"auto"}
              disabled={isSubmitting}
              onClick={onReject}
            >
              {t("components.practiceForm.reject")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const DataRow: React.FC<{
  attribute: string;
  value: any;
  isTextArea?: boolean;
}> = ({ attribute, value, isTextArea }) => {
  if (isTextArea) {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", fontSize: "16px" }}
      >
        <div style={{ width: "15%", minWidth: "8rem" }}>
          <Text>{attribute}</Text>
        </div>
        <Textarea
          id={"description"}
          name={"description"}
          disabled={true}
          value={value || undefined}
        />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", fontSize: "16px" }}>
      <div style={{ width: "15%", minWidth: "8rem" }}>
        <Text>{attribute}</Text>
      </div>
      <Text>{value}</Text>
    </div>
  );
};
