import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../../components/Common/Alert";
import { UserCredentials } from "../../components/UserProfile/UserCredentials";
import { useStores } from "../../hooks/useStores";
import { AlertMessage } from "../../types";
import { formatName } from "../../utils/methods";
import {
  ProfileContainer,
  StyledBrand,
  UserBrandContainer,
  CredentialsContainer,
  NominativeText,
} from "./style";

export const UserProfile: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();

  const [message, setMessage] = useState<AlertMessage>();

  const onUpdateUser = useCallback(
    (email: string, password?: string, confirmPassword?: string) => {
      if (password === confirmPassword) {
        try {
          session.updateCurrentUser(email, password);
          setMessage({
            type: "success",
            message: t("messages.successes.updateUserForm.generic"),
          });
        } catch (e) {
          setMessage({
            type: "danger",
            message: t("messages.errors.updateUserForm.generic"),
          });
        }
      } else {
        setMessage({
          type: "danger",
          message: t("messages.formValidations.common.passwordsNotMatch"),
        });
      }
    },
    [session, t]
  );
  if (!session.user) return <></>;
  return (
    <ProfileContainer>
      <CredentialsContainer>
        <UserCredentials onUpdateUser={onUpdateUser} user={session.user} />
        {message && (
          <div style={{ paddingTop: "1rem" }}>
            <Alert
              hasIcon={false}
              hasLink={false}
              isDismissible={false}
              solid={false}
              variant="contained"
              color={message.type}
            >
              {message.message}
            </Alert>
          </div>
        )}
      </CredentialsContainer>
      <UserBrandContainer>
        <StyledBrand color={session.user.color} />
        <NominativeText>
          {formatName(session.user.name, session.user.surname)}
        </NominativeText>
      </UserBrandContainer>
    </ProfileContainer>
  );
});
