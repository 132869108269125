import { PersonalDetails } from "../../../UI/Contacts/PersonalDetails";
import { StyledMain, StyledBody } from "./style";
import Scrollbar from "../../../UI/Scrollbar";
import {
  Tab,
  TabContent,
  TabList,
  TabPanel,
  TabWrap,
} from "../../../UI/Tab/Tab";
import { Text } from "rebass";
import { useTranslation } from "react-i18next";
import {
  AlertMessage,
  Commission,
  Competition,
  ContactItemType,
  ExtraSalesThreshold,
  ManagementCompetition,
  Objective,
  Practice,
  PracticeState,
  Role,
  SalesThreshold,
  User,
} from "../../../../types";
import { UserPracticesSummary } from "../../../Common/UserPracticesSummary";
import { RoleSummary } from "../../RoleSummary";
import { useCallback, useMemo, useState } from "react";
import { CenteredModal } from "../../../Common/CenteredModal";
import { PracticeForm } from "../../../Common/PracticeForm";
import { PracticeSummary } from "../../../Common/PracticeSummary";
import { Alert } from "../../../Common/Alert";
import { Button } from "../../../Common/Button";
import { getUserProgressCards } from "../../../../utils/others";

type Props = {
  message?: AlertMessage;
  user?: User;
  commissions?: Commission;
  role?: Role;
  objectives?: Objective[];
  period?: any;
  setPeriod?: any;
  onUpdatePractice?: (
    practiceId: number,
    state: PracticeState,
    paymentMonth?: Date
  ) => any;
  onCreatePractice?: (
    user: User,
    customer: string,
    date: Date,
    totalAmount: number,
    description?: string
  ) => any;
  onCreateObjective?: (roleId: number, text: string) => any;
  onUpdateObjective?: (id: number, text: string) => any;
  onDeleteObjective?: (id: number) => any;
  onCreateSalesThreshold?: (
    salesThreshold: SalesThreshold,
    competitionId: number
  ) => any;
  onUpdateSalesThreshold?: (
    salesThreshold: SalesThreshold,
    competitionId: number
  ) => any;
  onDeleteSalesThreshold?: (id: number) => any;

  onCreateExtraSalesThreshold?: (
    extraSalesThreshold: ExtraSalesThreshold,
    competitionId: number
  ) => any;
  onUpdateExtraSalesThreshold?: (
    extraSalesThreshold: ExtraSalesThreshold,
    competitionId: number
  ) => any;
  onDeleteExtraSalesThreshold?: (id: number) => any;
  onCreateManagementCompetition?: (
    managementCompetition: ManagementCompetition,
    competitionId: number
  ) => any;
  onUpdateManagementCompetition?: (
    managementCompetition: ManagementCompetition,
    competitionId: number
  ) => any;
  onDeleteManagementCompetition?: (id: number) => any;
  onCreateCompetition?: (startDate: Date, endDate: Date, roleId: number) => any;
  onUpdateCompetition?: (competition: Competition, roleId: number) => any;
  onDeleteCompetition?: (id: number) => any;
  onEditCheck?: (id: number, completed: boolean) => any;
  onUpdateUserRole?: (updateType: "upgrade" | "downgrade") => any;
  onDeleteUser?: () => any;
  onUpdateUser?: () => any;
  canUpgradeCurrentUserRole?: boolean;
  canDowngradeCurrentUserRole?: boolean;
  onFormedUserClick?: (item: ContactItemType) => void;
  roleMessage?: AlertMessage;
};

const Wrapper: React.FC<Props> = ({
  message,
  user,
  commissions,
  role,
  objectives,
  period,
  setPeriod,
  onUpdatePractice,
  onCreatePractice,
  onCreateObjective,
  onUpdateObjective,
  onDeleteObjective,
  onCreateSalesThreshold,
  onUpdateSalesThreshold,
  onDeleteSalesThreshold,
  onCreateExtraSalesThreshold,
  onUpdateExtraSalesThreshold,
  onDeleteExtraSalesThreshold,
  onCreateManagementCompetition,
  onUpdateManagementCompetition,
  onDeleteManagementCompetition,
  onCreateCompetition,
  onUpdateCompetition,
  onDeleteCompetition,
  onEditCheck,
  onUpdateUserRole,
  onDeleteUser,
  onUpdateUser,
  canUpgradeCurrentUserRole,
  canDowngradeCurrentUserRole,
  onFormedUserClick,
  roleMessage,
}: Props) => {
  const { t } = useTranslation();
  const [currentPractice, setCurrentPractice] = useState<
    Practice | undefined
  >();
  const [isCreatePracticeModalOpen, setIsCreatePracticeModalOpen] =
    useState(false);
  const [isUpdatePracticeModalOpen, setIsUpdatePracticeModalOpen] =
    useState(false);

  const progressCards = useMemo(
    () => (user && commissions ? getUserProgressCards(user, commissions) : []),
    [user, commissions]
  );
  const onPracticeClick = useCallback((practice: Practice) => {
    setCurrentPractice(practice);
    setIsUpdatePracticeModalOpen(true);
  }, []);

  return (
    <StyledMain className="main-content">
      <StyledBody>
        <TabWrap variation="line">
          <TabList>
            {user && (
              <>
                <Tab>
                  <Text as="span" display={["none", "inline"]}>
                    {t("components.contacts.userData")}
                  </Text>
                </Tab>
                <Tab>
                  <Text as="span" display={["none", "inline"]} pr="3px">
                    {t("components.contacts.practices")}
                  </Text>
                </Tab>
              </>
            )}

            {role && (
              <>
                <Tab>
                  <Text as="span" display={["none", "inline"]}>
                    {t("components.contacts.configuration")}
                  </Text>
                </Tab>
              </>
            )}
          </TabList>
          <TabContent>
            <Scrollbar top="0">
              {message && message.type !== "danger" && (
                <Alert
                  hasIcon={false}
                  hasLink={false}
                  isDismissible={false}
                  solid={false}
                  variant="contained"
                  color={message.type}
                >
                  {message.message}
                </Alert>
              )}
              {user && (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      paddingTop: "0.5rem",
                    }}
                  >
                    {onUpdateUser && (
                      <Button
                        color={"primary"}
                        fontSize={"16px"}
                        ml={"auto"}
                        mr={"1rem"}
                        onClick={onUpdateUser}
                      >
                        {t("common.edit")}
                      </Button>
                    )}
                    {onDeleteUser && (
                      <Button
                        color={"danger"}
                        fontSize={"16px"}
                        mr={"2rem"}
                        onClick={onDeleteUser}
                      >
                        {t("common.delete")}
                      </Button>
                    )}
                  </div>
                  <TabPanel>
                    <PersonalDetails
                      user={user}
                      commissions={commissions}
                      objectives={objectives}
                      onEditCheck={onEditCheck}
                      onUpdateUserRole={onUpdateUserRole}
                      canUpgradeCurrentUserRole={canUpgradeCurrentUserRole}
                      canDowngradeCurrentUserRole={canDowngradeCurrentUserRole}
                      onFormedUserClick={onFormedUserClick}
                    />
                  </TabPanel>
                  <TabPanel>
                    <UserPracticesSummary
                      user={user}
                      period={period}
                      setPeriod={setPeriod}
                      canCreatePractices={true}
                      progressItems={progressCards}
                      onPracticeClick={onPracticeClick}
                      onCreatePractice={(
                        customer,
                        date,
                        totalAmount,
                        description
                      ) => {
                        if (onCreatePractice)
                          onCreatePractice(
                            user,
                            customer,
                            date,
                            totalAmount,
                            description
                          );
                      }}
                    />
                  </TabPanel>
                </>
              )}

              {role && (
                <TabPanel>
                  {roleMessage && roleMessage.message !== "" && (
                    <Alert
                      hasIcon={false}
                      hasLink={false}
                      isDismissible={false}
                      solid={false}
                      variant="contained"
                      color={roleMessage.type}
                    >
                      {roleMessage.message}
                    </Alert>
                  )}
                  <RoleSummary
                    role={role}
                    onCreateObjective={onCreateObjective}
                    onUpdateObjective={onUpdateObjective}
                    onDeleteObjective={onDeleteObjective}
                    onCreateSalesThreshold={onCreateSalesThreshold}
                    onUpdateSalesThreshold={onUpdateSalesThreshold}
                    onDeleteSalesThreshold={onDeleteSalesThreshold}
                    onCreateExtraSalesThreshold={onCreateExtraSalesThreshold}
                    onUpdateExtraSalesThreshold={onUpdateExtraSalesThreshold}
                    onDeleteExtraSalesThreshold={onDeleteExtraSalesThreshold}
                    onCreateManagementCompetition={
                      onCreateManagementCompetition
                    }
                    onUpdateManagementCompetition={
                      onUpdateManagementCompetition
                    }
                    onDeleteManagementCompetition={
                      onDeleteManagementCompetition
                    }
                    onCreateCompetition={onCreateCompetition}
                    onUpdateCompetition={onUpdateCompetition}
                    onDeleteCompetition={onDeleteCompetition}
                  />
                </TabPanel>
              )}
            </Scrollbar>
          </TabContent>
        </TabWrap>
      </StyledBody>
      <CenteredModal
        title={t("components.practiceModal.createPractice")}
        isOpen={isCreatePracticeModalOpen}
        setIsOpen={setIsCreatePracticeModalOpen}
        size={"lg"}
      >
        <PracticeForm
          onSubmit={(customer, date, totalAmount, description) => {
            if (onCreatePractice && user)
              onCreatePractice(user, customer, date, totalAmount, description);
            setIsCreatePracticeModalOpen(false);
          }}
          onCancel={() => setIsCreatePracticeModalOpen(false)}
        />
      </CenteredModal>

      {currentPractice && (
        <CenteredModal
          title={t("common.practice") + " #" + currentPractice.id}
          isOpen={isUpdatePracticeModalOpen}
          setIsOpen={setIsUpdatePracticeModalOpen}
          size={"lg"}
        >
          <PracticeSummary
            practice={currentPractice}
            onSubmit={(state, paymentMonth) => {
              if (onUpdatePractice)
                onUpdatePractice(currentPractice.id, state, paymentMonth);
              setIsUpdatePracticeModalOpen(false);
            }}
            onCancel={() => setIsUpdatePracticeModalOpen(false)}
          />
        </CenteredModal>
      )}
    </StyledMain>
  );
};

export default Wrapper;
